import React from 'react';
import styles from '../index.less';
import EnterpriseTag from '@/components/enterpriseTag';
import { ICompanyInfo } from '@/types/enterpriseServiceBackend/addMembers';
import {
  companyReviewStateMap,
  companyReviewTimeMap,
} from '@/utils/enterpriseServiceBackend';
import { isUndefined } from 'lodash';
import { Space } from 'antd';

interface EnterpriseInfoCardProps {
  company: ICompanyInfo;
  showComName: boolean;
}

const EnterpriseInfoCard: React.FC<EnterpriseInfoCardProps> = ({
  company,
  showComName = true,
}) => {
  return (
    <Space
      direction={'vertical'}
      size={8}
      style={{ width: 340 }}
      className={styles.enterprise_details_info}
    >
      {showComName && (
        <div className={styles.company_name}>
          {company.comname}
          <span className={styles.value}>（mvid：{company.mvId}）</span>
        </div>
      )}
      <div
        className={styles.info_row}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div>CRM客户：</div>
        <div className={styles.ellipsis_text}>{company.crmComName}</div>
      </div>
      <div className={styles.info_row}>
        <div style={{ width: '30%' }}>
          成员数：<span className={styles.value}>{company.memberCount}</span>
        </div>
        <div style={{ width: '70%' }}>
          超管：
          <span className={styles.value}>
            {company.adminUser?.realname || company.adminUser?.username}
            {company.adminUser?.mobile && `（${company.adminUser?.mobile}）`}
          </span>
        </div>
      </div>
      <Space size={4} wrap>
        {!isUndefined(company.state) && companyReviewStateMap[company.state]()}
        <EnterpriseTag text="服务凭证申请" />
        <EnterpriseTag text={`经办人：${company.assignReviewerName || '--'}`} />
        {!isUndefined(company.state) &&
          company.reviewTime &&
          companyReviewTimeMap[company.state](
            company.entryTime,
            company.reviewTime,
          )}
      </Space>
    </Space>
  );
};

export default EnterpriseInfoCard;
