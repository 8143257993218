import React from 'react';
import styles from './index.less';
import cx from 'classnames';
import { ETagColor } from '@/components/enterpriseTag/color';

type Props = {
  color?: ETagColor;
  text: string;
};

const EnterpriseTag: React.FC<Props> = ({ color = ETagColor.GREY, text }) => {
  return (
    <div
      className={cx(
        styles.tag_wrapper,
        color === ETagColor.GREEN && styles.green,
        color === ETagColor.ORANGE && styles.orange,
        color === ETagColor.GREY && styles.grey,
      )}
    >
      {text}
    </div>
  );
};

export default EnterpriseTag;
