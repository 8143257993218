// 企业合作认证审核状态
import { ECompanyReviewState } from '@/types';
import EnterpriseTag from '@/components/enterpriseTag';
import { ETagColor } from '@/components/enterpriseTag/color';
import dayjs from 'dayjs';

export const companyReviewStateMap: {
  [key in ECompanyReviewState]: () => void;
} = {
  [ECompanyReviewState.FAILED]: () => null,
  [ECompanyReviewState.WAITING]: () => (
    <EnterpriseTag color={ETagColor.ORANGE} text={'待审核'} />
  ),
  [ECompanyReviewState.PASSED]: () => (
    <EnterpriseTag color={ETagColor.GREEN} text={'通过'} />
  ),
};
const handleTime = (time: string | undefined) => {
  if (time) {
    return dayjs(time).format('YYYY-MM-DD');
  }
  return '未知时间';
};
// 企业合作认证关键时间点
export const companyReviewTimeMap: {
  [key in ECompanyReviewState]: (entryTime: string, reviewTime: string) => void;
} = {
  [ECompanyReviewState.FAILED]: (entryTime, reviewTime) => null,
  [ECompanyReviewState.WAITING]: (entryTime, reviewTime) => (
    <EnterpriseTag text={`${handleTime(entryTime)}申请`} />
  ),
  [ECompanyReviewState.PASSED]: (entryTime, reviewTime) => (
    <EnterpriseTag text={`${handleTime(reviewTime)}通过`} />
  ),
};
