import { requestGet, requestPost, requestPut } from '@/utils/request';
import { TCompanyMergeForm } from '@/types/enterpriseService';
import { TApi } from '@/types/common';

// 获取公司信息
export function getEnterpriseInfo() {
  return requestGet('/service/api/company/info', {});
}

// 获取公司状态
export function getEnterpriseServiceState() {
  return requestGet('/service/api/company/state', {});
}

// 修改公司名称
export function updateEnterpriseName(params: string) {
  return requestPut(`/service/api/company/rename?name=${params}`, {});
}

// 催办
export function urgeAudit() {
  return requestGet(`/service/api/push/cooperate/verify`, {});
}

// 获取企业实名认证
export function getServiceAuthInterface() {
  return requestGet(`/service/api/auth`, {});
}

/**
 * 合并公司
 * @param form 表单
 */
export function mergeCompany(form: TCompanyMergeForm): Promise<TApi<void>> {
  return requestPost(`/companies/api/company/merge`, form);
}
