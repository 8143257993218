import React from 'react';
import { Form } from 'antd';
import { useModel } from '@@/plugin-model/useModel';
import FindAccount from '@/pages/enterpriseServiceBackend/addMembers/form/findAccount';
import JoinEnterprise from '@/pages/enterpriseServiceBackend/addMembers/form/joinEnterprise';
import { EJoinType } from '@/types/enterpriseServiceBackend/addMembers';

const AddMembersNewForm: React.FC<{}> = () => {
  const { form, addMembersStep } = useModel('useAddMembersModel', (model) => ({
    form: model.form,
    addMembersStep: model.addMembersStep,
  }));
  return (
    <Form
      name={'addMembersNew'}
      form={form}
      layout={'vertical'}
      initialValues={{ location: '+86', joinType: EJoinType.JOIN }}
    >
      {addMembersStep === 0 && <FindAccount />}
      {addMembersStep === 1 && <JoinEnterprise />}
    </Form>
  );
};

export default AddMembersNewForm;
