import React, { useEffect, useState } from 'react';
import { Radio, Input, Form, Space, Select } from 'antd';
import { useModel } from '@@/plugin-model/useModel';
import styles from '../index.less';
import { ECompanyRole } from '@/types';
import { ICompanyInfo } from '@/types/enterpriseServiceBackend/addMembers';
import { useRequest } from 'ahooks';
import {
  findCompanyByName,
  findCompanyWithCrmByName,
  getCrmCompaniesByMobile,
} from '@/services/enterpriseServiceBackend';
import debounce from 'lodash/debounce';
import { LoadingOutlined } from '@ant-design/icons';
import EnterpriseInfoCard from './components/enterpriseInfoCard';
import EnterpriseChoose from '@/components/enterpriseChoose';

interface EnterpriseItem {
  name: string;
  crmCustomer: string;
  mvid: string;
  memberCount: number;
  adminName: string;
  adminPhone: string;
  status: string;
  serviceStatus: string;
  operator: string;
  applyTime: string;
}

const Join: React.FC = () => {
  const { form, manualMvId, setManualMvId, mobile, setSelectCompany } =
    useModel('useAddMembersModel', (model) => ({
      form: model.form,
      manualMvId: model.manualMvId,
      setManualMvId: model.setManualMvId,
      mobile: model.mobile,
      setSelectCompany: model.setSelectCompany,
    }));
  const [selectedCompany, setSelectedCompany] = useState<ICompanyInfo | null>(
    null,
  );
  const [enterpriseByMobile, setEnterpriseByMobile] = useState<ICompanyInfo[]>(
    [],
  );
  const mvid = Form.useWatch('mvid', form);

  useRequest(
    () => {
      if (mobile) {
        return getCrmCompaniesByMobile(mobile);
      } else {
        return Promise.reject({});
      }
    },
    {
      onSuccess: (res) => {
        console.log('crmCompaniesByMobile: ', res);
        setEnterpriseByMobile(res.data);
        if (res.data.length <= 0) {
          form.setFieldValue('mvid', 'manual');
        }
      },
      refreshDeps: [mobile],
    },
  );

  const renderEnterpriseItem = (item: ICompanyInfo) => (
    <div className={styles.enterprise_item} key={item.mvId}>
      <Radio value={item.mvId}>
        <EnterpriseInfoCard showComName={true} company={item} />
      </Radio>
    </div>
  );

  const handleRadioChange = (e: any) => {
    const value = e.target.value;
    setSelectCompany(enterpriseByMobile?.find((item) => item.mvId === value));
    form.setFieldsValue({ enterprise: value });
  };

  useEffect(() => {
    if (form.getFieldValue('role') === undefined) {
      form.setFieldValue('role', ECompanyRole.MEMBER);
    }
  }, []);

  return (
    <React.Fragment>
      {enterpriseByMobile?.length > 0 && (
        <div className={styles.search_tip}>
          根据该手机查询结果，推荐加入以下企业
        </div>
      )}
      <Form.Item
        name={'mvid'}
        className={styles.enterprise_list}
        style={{ marginBottom: 10 }}
      >
        <Radio.Group onChange={handleRadioChange}>
          {enterpriseByMobile?.map((item) => renderEnterpriseItem(item))}
          <div className={styles.enterprise_item} style={{ width: 400 }}>
            <Radio value="manual">
              <div className={styles.manual_select}>
                <div>手动选择企业</div>
                {mvid == 'manual' && (
                  <div>
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <EnterpriseChoose
                        value={manualMvId}
                        style={{ width: 350, marginTop: 4 }}
                        withCrmInfo={true}
                        onCompanyInfoChange={(mvid, company) => {
                          // @ts-ignore
                          setManualMvId(mvid);
                          // @ts-ignore
                          setSelectCompany(company);
                          // @ts-ignore
                          setSelectedCompany(company);
                        }}
                      />
                    </span>
                    {selectedCompany && (
                      <EnterpriseInfoCard
                        showComName={false}
                        company={selectedCompany}
                      />
                    )}
                  </div>
                )}
              </div>
            </Radio>
          </div>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={'备注名'}
        name={'remark'}
        style={{ marginBottom: 10 }}
        extra={'备注名用于企业管理员区分成员身份，不对成员展示'}
      >
        <Input placeholder="请输入备注名..." maxLength={20} showCount />
      </Form.Item>

      <Form.Item label={'加入企业后角色'} name={'role'}>
        <Radio.Group>
          <Radio value={ECompanyRole.MEMBER}>普通成员</Radio>
          <Radio value={ECompanyRole.ADMIN}>子管理员</Radio>
        </Radio.Group>
      </Form.Item>
    </React.Fragment>
  );
};

export default Join;
