import React from 'react';
import styles from '../memberCard/index.less';
import { ICompanyInfo } from '@/types/enterpriseServiceBackend/addMembers';
import { Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface IMemberCardProps {
  company: ICompanyInfo;
  style?: React.CSSProperties;
  handleRemoveEnterprise?: (mvId: number) => void;
}

const OriginEnterpriseCard: React.FC<IMemberCardProps> = ({
  company,
  style,
  handleRemoveEnterprise,
}) => {
  return (
    <div className={styles.memberItem} style={style}>
      <div className={styles.memberInfo}>
        <div className={styles.username}>
          <div>
            {company.comname}
            <span className={styles.item_title} style={{ marginLeft: 8 }}>
              （mvid：{company.mvId}）
            </span>
          </div>
          {handleRemoveEnterprise && (
            <div>
              <DeleteOutlined
                className={styles.deleteIcon}
                onClick={() => handleRemoveEnterprise?.(company.mvId)}
              />
            </div>
          )}
        </div>
        <Space size={2}>
          <span className={styles.item_title}>成员数：</span>
          <span className={styles.item_value}>{company.memberCount}</span>
          <span className={styles.item_title} style={{ marginLeft: 12 }}>
            超管：
          </span>
          <span className={styles.item_value}>
            {company.adminUser?.realName || company.adminUser?.username}
            {company.adminUser?.mobile
              ? `（${company.adminUser?.mobile}）`
              : ''}
          </span>
        </Space>
      </div>
    </div>
  );
};

export default OriginEnterpriseCard;
