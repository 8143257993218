import { TApi } from '@/types/common';
import { requestGet, requestPost } from '@/utils/request';
import { TPermissionGroup } from '../type';
import { Organization, OriginRoleItem } from './type';

/**
 * 获取权限组列表
 * @param type
 */
export async function listPermissionGroup(
  type: number,
): Promise<TApi<TPermissionGroup[]>> {
  return requestGet(`/companies/api/permission/group`, { type });
}

/**
 * 新建权限组
 * @param form
 */
export function addPermissionGroup(
  form: TPermissionGroup,
): Promise<TApi<TPermissionGroup>> {
  console.log('bxd');
  return requestPost(`/companies/api/permission/group`, form);
}

/**
 * 获取人员选择器树
 */
export function getTreeList(): Promise<TApi<OriginRoleItem[]>> {
  return requestGet(`/roleManage/api/role/tree/list`, {});
}

export function getOrganization(): Promise<TApi<Organization>> {
  return requestGet('/roleManage/api/authority/organization', {});
}
