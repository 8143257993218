// 企业合并
import EnterpriseChoose from '@/components/enterpriseChoose';
import { mergeCompany } from '@/services/enterpriseService';
import { TCompanyMergeForm } from '@/types/enterpriseService';
import { ICompanyInfo } from '@/types/enterpriseServiceBackend/addMembers';
import { useRequest } from '@@/plugin-request/request';
import {
  DeleteOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { NoAccessPage, useWebTrack } from '@digit-fe/digit-component';
import { Button, Card, Form, message, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useRef, useState } from 'react';
import styles from './index.less';
import useGetUserInfo from '@/hooks/useGetUserInfo';
import React from 'react';
import cx from 'classnames';

const CompanyInfoCard = (props: {
  index?: number;
  company: ICompanyInfo;
  showDeleteIcon?: boolean;
  onDelete?: () => void;
}) => {
  const { index, company, showDeleteIcon = true, onDelete } = props;

  return (
    <Card className={styles.companyInfoCard} bordered={false}>
      <div className={styles.cardHeader}>
        <div className={styles.companyNameContainer}>
          <span className={styles.companyName} title={company.comname}>
            {index ? (
              <>
                {index}.{company.comname}
              </>
            ) : (
              <>{company.comname}</>
            )}
          </span>
          <span className={styles.mvid}>&nbsp;(mvid: {company.mvId})</span>
        </div>
      </div>
      <div
        className={styles.cardBody}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div className={styles.infoItem} style={{ flexShrink: 0 }}>
          <span style={{ color: '#808696' }}>成员数：</span>
          {company.memberCount}
        </div>
        <div className={cx(styles.infoItem, styles.ellipsis_text)}>
          <span style={{ color: '#808696' }}>超管：</span>
          {company.adminUser?.realName || company.adminUser?.username} (
          {company.adminUser?.mobile})
        </div>
      </div>
      {showDeleteIcon && (
        <DeleteOutlined
          className={styles.deleteIcon}
          onClick={() => {
            onDelete?.();
          }}
        />
      )}
    </Card>
  );
};

const MergeSuccess = ({
  targetCompany,
  sourceCompanies,
  onContinue,
}: {
  targetCompany: ICompanyInfo;
  sourceCompanies: ICompanyInfo[];
  onContinue: () => void;
}) => {
  return (
    <div className={styles.mergeSuccess}>
      <div className={styles.company_merge_content}>
        <div className={styles.scrollContent}>
          <div className={styles.successIcon}>
            <img
              style={{ width: 200, height: 200 }}
              src="https://hwobs.fanruan.com/ui/color-success.png"
              alt="成功"
            />
          </div>
          <div className={styles.successTitle}>企业合并成功</div>
          <div className={styles.successTip}>
            请告知客户刷新页面或重新登录后查看
          </div>

          <div className={styles.companyInfoSection}>
            <div className={styles.sectionTitle}>合并到企业：</div>
            <div className={styles.companyInfoWrapper}>
              <CompanyInfoCard company={targetCompany} showDeleteIcon={false} />
            </div>
          </div>

          <div className={styles.companyInfoSection}>
            <div className={styles.sectionTitle}>被合并企业：</div>
            <div className={styles.companyInfoWrapper}>
              {sourceCompanies.map((company, _index) => (
                <CompanyInfoCard company={company} showDeleteIcon={false} />
              ))}
            </div>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            type="default"
            className={styles.continueButton}
            onClick={onContinue}
          >
            继续为其他客户操作
          </Button>
        </div>
      </div>
    </div>
  );
};

const CompanyMerge = () => {
  const isFrAccount = true;
  const isHasAccess = true;
  const { sendEvent } = useWebTrack();
  const [form] = useForm();

  const [mergeSuccess, setMergeSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [selectedTarget, setSelectedTarget] = useState<ICompanyInfo | null>(
    null,
  );
  const [selectedSources, setSelectedSources] = useState<ICompanyInfo[]>([]);
  const userInfo = useGetUserInfo();

  const selectedCompaniesRef = useRef<HTMLDivElement>(null);

  const { run: mergeCompanyRun } = useRequest(
    (form: TCompanyMergeForm) => {
      setSubmitting(true);
      return mergeCompany(form);
    },
    {
      manual: true,
      onSuccess: () => {
        setSubmitting(false);
        setMergeSuccess(true);
        message.success('企业合并成功').then();
      },
      onError: (error) => {
        setSubmitting(false);
        message.error(error.message || '企业合并失败').then();
      },
    },
  );

  useEffect(() => {
    // 检测是否有滚动条
    const checkForScrollbar = () => {
      const container = selectedCompaniesRef.current;
      if (container) {
        const hasScrollbar = container.scrollHeight > container.clientHeight;
        if (hasScrollbar) {
          container.classList.add('has-scrollbar');
        } else {
          container.classList.remove('has-scrollbar');
        }
      }
    };

    // 初始检测
    checkForScrollbar();

    // 当选中的企业变化时重新检测
    const observer = new MutationObserver(checkForScrollbar);
    if (selectedCompaniesRef.current) {
      observer.observe(selectedCompaniesRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => observer.disconnect();
  }, [selectedSources]);

  const handleContinue = () => {
    // 重置状态
    setMergeSuccess(false);
    form.resetFields();
    setSelectedSources([]);
    setSelectedTarget(null);
    setErrorMessage('');
    setBtnDisabled(true);
  };

  const validateSelected = (values: any) => {
    const targetMvid = values.target;
    const sourceMvids = values.source;

    // 如果targetMvid或者sorceMvids为空，直接返回true
    if (!targetMvid || !sourceMvids || sourceMvids.length === 0) {
      setErrorMessage('');
      setBtnDisabled(true);
      return false;
    }

    if (sourceMvids.includes(targetMvid)) {
      setErrorMessage('被合并的企业中不能包含合并到的企业，请重新选择企业。');
      setBtnDisabled(true);
      return false;
    }
    setErrorMessage('');
    setBtnDisabled(false);
    return true;
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (!validateSelected(values)) {
          return;
        }

        // 清除错误信息
        setErrorMessage('');

        Modal.confirm({
          title: '确认合并以下企业吗？此操作无法撤销。',
          icon: <QuestionCircleOutlined />,
          content: (
            <div className={styles.confirmContent}>
              <div className={styles.confirmSection}>
                <div className={styles.confirmLabel}>合并到企业：</div>
                {selectedTarget && (
                  <div className={styles.confirmValue}>
                    {selectedTarget.comname} (mvid:{selectedTarget.mvId})
                  </div>
                )}
              </div>

              <div className={styles.confirmSection}>
                <div className={styles.confirmLabel}>被合并企业：</div>
                <div>
                  {selectedSources.map((company, index) => (
                    <div key={company.mvid} className={styles.confirmValue}>
                      {index + 1}.{company.comname} (mvid: {company.mvId})
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ),
          onOk: handleConfirmMerge,
          okText: '合并',
          cancelText: '取消',
          width: 500,
        });
      })
      .catch(() => {
        // 表单验证失败
      });
  };

  const handleConfirmMerge = () => {
    if (!selectedTarget || !selectedSources) return;

    const req = {
      source: selectedSources.map((item) => item.mvId),
      target: selectedTarget.mvId,
    };
    // 执行合并操作
    mergeCompanyRun(req).then(() => {
      // 发送事件
      sendEvent({
        uid: userInfo?.uid || '',
        operator: `${userInfo?.nameEn}-${userInfo?.nameZh}`,
        name: '企业合并',
        ...req,
      });
    });
  };

  if (!isFrAccount || !isHasAccess) {
    return (
      <NoAccessPage
        switchAccountHref={`${process.env.ID_URL}/login/token/logout?referrer=${window.location.href}`}
        isFrAccount={isFrAccount}
      />
    );
  }

  return (
    <div className={styles.company_merge}>
      {userInfo.permission.canMergeCompany ? (
        <React.Fragment>
          {mergeSuccess ? (
            <MergeSuccess
              targetCompany={selectedTarget!}
              sourceCompanies={selectedSources}
              onContinue={handleContinue}
            />
          ) : (
            <div className={styles.company_merge_content}>
              <div className={styles.scrollContent}>
                <div className={styles.tip_container}>
                  <div className={styles.tip}>
                    <InfoCircleOutlined className={styles.icon} />
                    <div className={styles.text}>
                      <p>
                        1.
                        可把多个企业下的成员合并到指定的企业中，合并完成后被合并的企业将会解散，被合并的企业原超管会变成合并后企业的普通管理员；
                      </p>
                      <p>2. 请先核实企业情况后再进行操作，将会记录操作日志；</p>
                      <p>3. 合并操作无法撤销，请谨慎操作。</p>
                    </div>
                  </div>
                </div>

                <Form
                  form={form}
                  layout="vertical"
                  className={styles.form}
                  onValuesChange={() => {
                    validateSelected(form.getFieldsValue());
                  }}
                >
                  <Form.Item noStyle>
                    <Form.Item label={'合并到的企业'} name="target" required>
                      <EnterpriseChoose
                        style={{ width: '100%' }}
                        onCompanyInfoChange={(_mvid, comInfo) => {
                          setSelectedTarget(comInfo as ICompanyInfo);
                        }}
                      />
                    </Form.Item>
                    {selectedTarget && (
                      <CompanyInfoCard
                        company={selectedTarget}
                        showDeleteIcon={true}
                        onDelete={() => {
                          setSelectedTarget(null);
                          form.resetFields(['target']);
                          validateSelected(form.getFieldsValue());
                        }}
                      />
                    )}
                  </Form.Item>

                  <Form.Item noStyle>
                    <Form.Item label={'被合并的企业'} name="source" required>
                      <EnterpriseChoose
                        multiple={true}
                        style={{ width: '100%' }}
                        onCompanyInfoChange={(_mvid, comInfo) => {
                          setSelectedSources(comInfo as ICompanyInfo[]);
                        }}
                      />
                    </Form.Item>
                    <div
                      className={styles.selectedCompanies}
                      ref={selectedCompaniesRef}
                    >
                      {selectedSources &&
                        selectedSources.map((company, index) => (
                          <CompanyInfoCard
                            key={index + 1}
                            index={index + 1}
                            company={company}
                            showDeleteIcon={true}
                            onDelete={() => {
                              setSelectedSources((prev) =>
                                prev.filter(
                                  (item) => item.mvId !== company.mvId,
                                ),
                              );
                              form.setFieldValue(
                                'source',
                                form
                                  .getFieldValue('source')
                                  .filter(
                                    (item: number) => item != company.mvId,
                                  ),
                              );
                              validateSelected(form.getFieldsValue());
                            }}
                          />
                        ))}
                    </div>
                  </Form.Item>
                </Form>
              </div>

              {/* 错误提示放在按钮上方 */}
              <div className={styles.buttonContainer}>
                {errorMessage && (
                  <div className={styles.errorContainer}>{errorMessage}</div>
                )}
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.submit_button}
                  onClick={handleSubmit}
                  loading={submitting}
                  disabled={submitting || btnDisabled}
                >
                  进行合并
                </Button>
              </div>
            </div>
          )}
        </React.Fragment>
      ) : (
        <NoAccessPage
          switchAccountHref={`${process.env.ID_URL}/login/token/logout?referrer=${window.location.href}`}
          isFrAccount={isFrAccount}
        />
      )}
    </div>
  );
};

export default CompanyMerge;
