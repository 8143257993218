import React from 'react';
import styles from './index.less';
import { useModel } from '@@/plugin-model/useModel';
import { companyRoleMap, ECompanyRole } from '@/types';
import { EJoinType } from '@/types/enterpriseServiceBackend/addMembers';

interface ISuccessProps {}

const AddMembersSuccess: React.FC<ISuccessProps> = () => {
  const { customerAccountInfo, newCompanyInfo } = useModel(
    'useAddMembersModel',
    (model) => ({
      customerAccountInfo: model.customerAccountInfo,
      newCompanyInfo: model.newCompanyInfo,
    }),
  );

  return (
    <div className={styles.success_page}>
      <img
        src={'https://hwobs.fanruan.com/ui/color-success.png'}
        style={{ width: 200, height: 200 }}
      />
      <div className={styles.success_title}>
        {newCompanyInfo?.joinType === EJoinType.CREATE
          ? '企业创建成功'
          : '成员添加成功'}
      </div>
      <div className={styles.success_subTitle} style={{ textAlign: 'center' }}>
        {customerAccountInfo?.uid
          ? '请告知客户刷新页面或重新登录后查看。'
          : '已为客户创建账号并加入企业。请告知客户使用手机验证码登录后查看'}
      </div>
      <div className={styles.infoCard}>
        <div className={styles.content_wrapper}>
          <div className={styles.details_tips} style={{ marginBottom: 8 }}>
            <div className={styles.qitem}>客户手机号：</div>
            <div className={styles.qvalue}>
              {newCompanyInfo?.location} {newCompanyInfo?.mobile}
            </div>
          </div>
          <div className={styles.details_tips} style={{ marginBottom: 10 }}>
            <div className={styles.qitem}>加入企业：</div>
            <div className={styles.qvalue}>
              {newCompanyInfo?.comname}
              {newCompanyInfo?.mvId && (
                <span>（mvid：{newCompanyInfo?.mvId}）</span>
              )}
            </div>
          </div>
          <div className={styles.details_tips}>
            <div className={styles.qitem}>角色：</div>
            <div className={styles.qvalue}>
              {newCompanyInfo?.joinType === EJoinType.CREATE
                ? '超级管理员'
                : companyRoleMap[
                    (newCompanyInfo?.role as ECompanyRole) ||
                      ECompanyRole.MEMBER
                  ]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMembersSuccess;
