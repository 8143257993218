import { useState } from 'react';
import { Form } from 'antd';
import {
  IAccountInfo,
  ICompanyInfo,
  ICrmCustomerInfo,
} from '@/types/enterpriseServiceBackend/addMembers';

export default function useAddMembersModel() {
  // 客户账户信息
  const [customerAccountInfo, setCustomerAccountInfo] =
    useState<IAccountInfo>();
  // 客户所在公司信息
  const [customerCompanyInfo, setCustomerCompanyInfo] =
    useState<ICompanyInfo>();
  // 表单
  const [form] = Form.useForm();
  const [mobile, setMobile] = useState<string | undefined>();
  // 手机号校验结果
  const [mobileValid, setMobileValid] = useState<boolean>(false);
  // 手动选择的公司
  const [manualMvId, setManualMvId] = useState<number | undefined>();
  // 加入企业 手动选择的公司
  const [selectCompany, setSelectCompany] = useState<ICompanyInfo>();
  // 创建企业 选择的公司
  const [selectCrmCompany, setSelectCrmCompany] = useState<ICrmCustomerInfo>();
  // 步骤条
  const [addMembersStep, setAddMembersStep] = useState<number>(0);
  const [newCompanyInfo, setNewCompanyInfo] = useState<any>();
  const goToNextStep = () => {
    setAddMembersStep((prev) => (prev >= 2 ? 2 : prev + 1));
  };
  const goToPrevStep = () => {
    setManualMvId(undefined);
    form.resetFields();
    form.setFieldValue('mobile', mobile);
    setAddMembersStep((prev) => (prev <= 0 ? 0 : prev - 1));
  };

  const clearFormAndInfo = () => {
    setCustomerAccountInfo(undefined);
    setCustomerCompanyInfo(undefined);
    form.resetFields();
    setMobile(undefined);
    setMobileValid(false);
    setManualMvId(undefined);
    setSelectCompany(undefined);
    setNewCompanyInfo(undefined);
    setSelectCrmCompany(undefined);
    setAddMembersStep(0);
    setNewCompanyInfo(undefined);
  };

  return {
    form,
    addMembersStep,
    goToNextStep,
    goToPrevStep,
    customerAccountInfo,
    setCustomerAccountInfo,
    customerCompanyInfo,
    setCustomerCompanyInfo,
    mobileValid,
    setMobileValid,
    manualMvId,
    setManualMvId,
    mobile,
    setMobile,
    selectCompany,
    setSelectCompany,
    newCompanyInfo,
    setNewCompanyInfo,
    clearFormAndInfo,
    selectCrmCompany,
    setSelectCrmCompany,
  };
}
