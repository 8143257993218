import { useState } from 'react';
import {
  ICompanyInfo,
  IEnterpriseMemberInfo,
} from '@/types/enterpriseServiceBackend/addMembers';

export default function useDeleteMembersModel() {
  const [companyInfo, setCompanyInfo] = useState<ICompanyInfo>();
  const [selectedMembers, setSelectedMembers] = useState<
    IEnterpriseMemberInfo[]
  >([]);

  return {
    companyInfo,
    setCompanyInfo,
    selectedMembers,
    setSelectedMembers,
  };
}
