import React, { ReactNode, useRef, useEffect, useState } from 'react';
import styles from './index.less';
import { Empty, Select, Input } from 'antd';
import { useRequest, useUpdateEffect } from 'ahooks';
import {
  findCompanyByName,
  findCompanyWithCrmByName,
  IFindCompanyByName,
} from '@/services/enterpriseServiceBackend';
import { ICompanyInfo } from '@/types/enterpriseServiceBackend/addMembers';
import { LoadingOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { TApi } from '@/types/common/interface';
import { useMediaQuery } from 'react-responsive';
import { InfiniteScroll, List, Popup, SearchBar } from 'antd-mobile';
import { escapeRegExp } from 'lodash';
import cx from 'classnames';

type Props = {
  value?: number | number[];
  onChange?: (value: number | number[]) => void;
  onCompanyInfoChange?: (
    mvid: number | number[],
    comInfo: ICompanyInfo | ICompanyInfo[],
  ) => void;
  style?: React.CSSProperties;
  multiple?: boolean;
  withCrmInfo?: boolean;
};

const EnterpriseChoose: React.FC<Props> = ({
  value,
  onChange,
  onCompanyInfoChange,
  style,
  multiple = false,
  withCrmInfo = false,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [companies, setCompanies] = useState<ICompanyInfo[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [total, setTotal] = useState(0);
  const [scrollLoading, setScrollLoading] = useState(false);
  const isNotPc = useMediaQuery({ query: '(max-width: 1199px)' });
  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const isDropDownMustActiveRef = useRef(false);
  const setIsDropDownMustActive = (value: boolean) => {
    isDropDownMustActiveRef.current = value;
  };
  const [open, setOpen] = React.useState(false);

  useUpdateEffect(() => {
    if (!searchValue) {
      return;
    }
    if (!companies) {
      return;
    }
    setCompanies((prev) =>
      prev.filter((item) => {
        if (multiple) {
          return (value as number[]).includes(item.mvId);
        } else {
          return value == item.mvId;
        }
      }),
    );
  }, [searchValue]); // 当搜索词变更时，保留已选中的企业

  const { loading, run } = useRequest(
    async (search: string, currentPage: number) => {
      if (search !== undefined && search.trim() !== '') {
        setScrollLoading(true);
        try {
          let res: TApi<IFindCompanyByName>;
          const queryParams = {
            current: currentPage,
            size: 10,
            key: search,
          };
          if (withCrmInfo) {
            res = await findCompanyWithCrmByName(queryParams);
          } else {
            res = await findCompanyByName(queryParams);
          }
          setCompanies((prev) => [
            ...prev,
            ...(res.data.records.filter(
              (item) => !prev.map((r) => r.mvId).includes(item.mvId),
            ) || []),
          ]);
          if (currentPage === 1) {
            setTotal(res.data.total || 0);
          }
          setHasMore(
            (res.data.records || []).length === 10 &&
              companies.length < res.data.total,
          );
        } finally {
          setScrollLoading(false);
        }
      } else {
        return Promise.reject();
      }
    },
    {
      manual: true,
    },
  );

  const debouncedSearch = React.useMemo(
    () =>
      debounce((value: string) => {
        setPage(1);
        run(value, 1);
      }, 300),
    [run],
  );

  const handleSearch = (value: string) => {
    if (!multiple) {
      setSearchValue(value);
    }
    setIsDropDownMustActive(true);
    debouncedSearch(value);
  };

  const handlePopupScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { target } = e;
    const div = target as HTMLDivElement;
    if (
      div.scrollHeight - div.scrollTop <= div.clientHeight + 100 &&
      !loading &&
      hasMore
    ) {
      const nextPage = page + 1;
      setPage(nextPage);
      run(searchValue, nextPage);
    }
  };

  const dropdownRender = (menu: React.ReactElement) => (
    <div>
      {menu}
      {scrollLoading && companies.length < total && (
        <div style={{ padding: '8px 0', textAlign: 'center' }}>
          <LoadingOutlined style={{ marginRight: 8 }} />
          加载中...
        </div>
      )}
    </div>
  );

  const highlightText = (text: string) => {
    if (!searchValue || !text) return text;
    const searchValueEscaped = escapeRegExp(searchValue);
    const regex = new RegExp(`(${searchValueEscaped})`, 'gi');
    return (
      <>
        {text.split(regex).map((part, i) => (
          <React.Fragment key={i}>
            {part === searchValue ? (
              <span style={{ color: '#fa8c16' }}>{part}</span>
            ) : (
              part
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  const triggerChange = (mvid: number | number[] | undefined) => {
    if (mvid === undefined) {
      return;
    }
    onChange?.(mvid);
    if (multiple) {
      const selected = companies.filter((item) =>
        (mvid as number[]).includes(item.mvId),
      );
      if (selected) {
        onCompanyInfoChange?.(mvid, selected);
      }
    } else {
      const selected = companies.find((item) => item.mvId === mvid);
      if (selected) {
        onCompanyInfoChange?.(mvid, selected);
      }
    }
  };

  const [popupSelectedCompany, setPopupSelectedCompany] = useState<number[]>(
    [],
  );

  const handlePopupClick = (val: number) => {
    if (!multiple) {
      const numericValue = val ? Number(val) : undefined;
      triggerChange(numericValue);
      setPopupSelectedCompany([val]);
      setPopupVisible(false);
    } else {
      if (Array.isArray(value)) {
        if (value.includes(val)) {
          // 如果存在则移除
          setPopupSelectedCompany(value.filter((item) => item !== val));
        } else {
          // 如果不存在则添加
          setPopupSelectedCompany([...value, val]);
        }
      }
    }
  };

  useEffect(() => {
    if (multiple) {
      triggerChange(popupSelectedCompany);
    }
  }, [popupSelectedCompany]);

  return (
    <React.Fragment>
      {isNotPc ? (
        <React.Fragment>
          <SearchBar
            placeholder={'输入mvid或企业名称搜索'}
            onSearch={(value) => {
              handleSearch(value);
              setPopupVisible(true);
              if (multiple) {
                setSearchValue(value);
              }
            }}
          />
          <Popup
            visible={popupVisible}
            onMaskClick={() => setPopupVisible(false)}
            bodyStyle={{
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              minHeight: '40vh',
              maxHeight: '80vh',
              textAlign: loading ? 'center' : 'left',
              overflow: 'auto',
              height: '80vh', // 固定高度触发滚动
            }}
          >
            <List>
              {companies?.map((item, index) => (
                <List.Item key={item.mvId}>
                  {withCrmInfo ? (
                    <CompanyOptionWithCrm
                      item={item}
                      highlightText={highlightText}
                      onClick={handlePopupClick}
                      selected={
                        Array.isArray(value)
                          ? value?.includes(item.mvId)
                          : value === item.mvId
                      }
                    />
                  ) : (
                    <CompanyOption
                      item={item}
                      highlightText={highlightText}
                      onClick={handlePopupClick}
                      selected={
                        Array.isArray(value)
                          ? value?.includes(item.mvId)
                          : value === item.mvId
                      }
                    />
                  )}
                </List.Item>
              ))}
            </List>
            <InfiniteScroll
              // @ts-ignore
              loadMore={() => {
                if (hasMore && !loading) {
                  const nextPage = page + 1;
                  setPage(nextPage);
                  run(searchValue, nextPage);
                }
              }}
              hasMore={hasMore}
              loading={loading}
            >
              {!hasMore && companies.length == 0 && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <div style={{ textAlign: 'center' }}>
                      未找到已认证企业，换个关键词试试
                      <br />
                      或使用上方「创建新企业」功能
                    </div>
                  }
                />
              )}
            </InfiniteScroll>
          </Popup>
        </React.Fragment>
      ) : (
        <div style={{ position: 'relative' }}>
          {multiple ? (
            <React.Fragment>
              <Input
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: 36,
                  zIndex: 100,
                }}
                onChange={(e) => {
                  const val = e.target.value;
                  setOpen(true);
                  setSearchValue(val);
                  handleSearch(val);
                }}
                onFocus={() => {
                  setOpen(true);
                  handleSearch(searchValue);
                }}
                onBlur={() => {
                  setTimeout(() => {
                    if (!isDropDownMustActiveRef.current) {
                      setOpen(false);
                    }
                  }, 100);
                }}
                placeholder={'请输入关键词搜索企业...'}
              />
              <Select
                style={{ width: 300, ...style }}
                mode={'multiple'}
                showSearch
                placeholder="请输入关键词搜索企业..."
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={triggerChange}
                value={value}
                onBlur={() => {
                  setIsDropDownMustActive(false);
                  setOpen(false);
                }}
                open={open}
                notFoundContent={
                  loading ? (
                    '加载中...'
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        <div style={{ textAlign: 'center' }}>
                          未找到已认证企业，换个关键词试试
                          <br />
                          或使用上方「创建新企业」功能
                        </div>
                      }
                    />
                  )
                }
                onPopupScroll={handlePopupScroll}
                dropdownRender={dropdownRender}
                maxTagCount={0}
                options={companies.map((item) => ({
                  label: withCrmInfo ? (
                    <CompanyOptionWithCrm
                      item={item}
                      highlightText={highlightText}
                    />
                  ) : (
                    <CompanyOption item={item} highlightText={highlightText} />
                  ),
                  value: item?.mvId,
                  title: `${item?.comname} (mvid: ${item?.mvId})`,
                }))}
                optionLabelProp="title"
                popupClassName={styles.enterpriseSelect}
              />
            </React.Fragment>
          ) : (
            <Select
              style={{ width: 300, ...style }}
              showSearch
              placeholder="请输入关键词搜索企业..."
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={handleSearch}
              onChange={triggerChange}
              value={value}
              notFoundContent={
                loading ? (
                  '加载中...'
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      <div style={{ textAlign: 'center' }}>
                        未找到已认证企业，换个关键词试试
                        <br />
                        或使用上方「创建新企业」功能
                      </div>
                    }
                  />
                )
              }
              onPopupScroll={handlePopupScroll}
              dropdownRender={dropdownRender}
              maxTagCount={0}
              options={companies.map((item) => ({
                label: withCrmInfo ? (
                  <CompanyOptionWithCrm
                    item={item}
                    highlightText={highlightText}
                  />
                ) : (
                  <CompanyOption item={item} highlightText={highlightText} />
                ),
                value: item?.mvId,
                title: `${item?.comname} (mvid: ${item?.mvId})`,
              }))}
              optionLabelProp="title"
              popupClassName={styles.enterpriseSelect}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default EnterpriseChoose;

const CompanyOptionWithCrm: React.FC<{
  item?: ICompanyInfo;
  highlightText: (text: string) => string | ReactNode;
  onClick?: (mvid: number) => void;
  selected?: boolean;
}> = ({ item, highlightText, onClick, selected = false }) => {
  return (
    <div
      className={cx(styles.optionItem, selected && 'optionItemSelected')}
      onClick={() => {
        if (item?.mvId) {
          onClick?.(item?.mvId);
        }
      }}
    >
      <div className={styles.mainInfo}>
        <div
          className={styles.comname}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className={styles.ellipsis_text}>
            {highlightText(item?.comname || '')}
          </div>
          <div className={styles.mvid}>
            {highlightText(`(mvid: ${item?.mvId})`)}
          </div>
        </div>
        {item?.crmComName && (
          <div className={styles.infos} style={{ marginBottom: 4 }}>
            <div className={styles.qitem}>CRM客户：</div>
            <div className={cx(styles.qvalue, styles.qcrm)}>
              {highlightText(item?.crmComName || '')}
            </div>
          </div>
        )}
        <div className={styles.infos}>
          <div className={styles.infos} style={{ marginRight: 12 }}>
            <div className={styles.qitem}>成员数：</div>
            <div className={styles.qvalue}>{item?.memberCount}</div>
          </div>
          <div className={styles.infos}>
            <div className={styles.qitem}>超管：</div>
            <div className={styles.qvalue}>
              <div className={styles.qname}>
                {item?.adminUser?.realname || item?.adminUser?.username}
              </div>
              <div className={styles.qmobile}>
                （{item?.adminUser?.mobile}）
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CompanyOption: React.FC<{
  item?: ICompanyInfo;
  highlightText: (text: string) => string | ReactNode;
  onClick?: (mvid: number) => void;
  selected?: boolean;
}> = ({ item, highlightText, onClick, selected = false }) => {
  return (
    <div
      className={cx(styles.optionItem, selected && 'optionItemSelected')}
      onClick={() => {
        if (item?.mvId) {
          onClick?.(item?.mvId);
        }
      }}
    >
      <div className={styles.mainInfo}>
        <div
          className={styles.comname}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className={styles.ellipsis_text}>
            {highlightText(item?.comname || '')}
          </div>
          <div className={styles.mvid}>
            {highlightText(`(mvid: ${item?.mvId})`)}
          </div>
        </div>
        <div className={styles.infos}>
          <div className={styles.infos} style={{ width: 97, marginRight: 12 }}>
            <div className={styles.qitem}>成员数：</div>
            <div className={styles.qvalue}>{item?.memberCount}</div>
          </div>
          <div className={styles.infos}>
            <div className={styles.qitem}>超管：</div>
            <div className={styles.qvalue}>
              <div
                className={styles.qname}
                style={{ width: 'auto', maxWidth: 150 }}
              >
                {highlightText(
                  item?.adminUser?.realName || item?.adminUser?.username,
                )}
              </div>
              <div className={styles.qmobile}>
                （{item?.adminUser?.mobile}）
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
