import { PlusSquareOutlined } from '@ant-design/icons';

import {
  IPeopleSelectMemberObj,
  IPeopleSelectRoleObj,
  PeopleRoleSelect,
  SelectMember,
  SelectRoleListItem,
} from '@digit-fe/digit-component';
import { useRequest, useUpdateEffect } from 'ahooks';
import { Tag } from 'antd';
import { isUndefined, map } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useImmer } from 'use-immer';

import styles from './index.less';

import { EPermissionGroup, TPermissionGroup } from '../type';

import {
  addPermissionGroup,
  getOrganization,
  getTreeList,
  listPermissionGroup,
} from './request';

import { getSuperAuthorityItem } from '@/services/permission';

type TPermissionCardProps = {
  title: string;
  subTitle?: string;
  authority?: boolean;
  type?: EPermissionGroup;
};
const PermissionCard: React.FC<TPermissionCardProps> = ({
  title,
  subTitle,
  authority = true,
  type,
}) => {
  const [adminList, setAdminList] = useState<string[]>();
  const [permissionGroup, updatePermissionGroup] = useImmer<TPermissionGroup>({
    name: title,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    permission: {},
  });
  const [isFirstRun, setIsFirstRun] = useState(true);
  const selected: string[] = [];
  const memberNodes: SelectMember[] = [];
  const groupNodes: SelectRoleListItem[] = [];
  permissionGroup.selected?.personList.forEach((item) => {
    selected.push(`${item.nameEn}-${item.nameZh}`);
    memberNodes.push({
      id: item.pernr,
      completeName: `${item.nameEn}-${item.nameZh}`,
    });
  });
  permissionGroup.selected?.roleList.forEach((item) => {
    selected.push(item.name);
    groupNodes.push({
      id: item.id,
      name: item.name,
      completeName: item.name,
    });
  });

  const { run: listPermissionGroupRun } = useRequest(
    (type: EPermissionGroup) => {
      // eslint-disable-next-line no-undefined
      return listPermissionGroup(type);
    },
    {
      manual: true,
      onSuccess: (res) => {
        const data = res.data;
        if (data?.length > 0) {
          updatePermissionGroup((draft) => {
            const firstItem = data[0];
            draft.permission = firstItem?.permission;
            draft.name = firstItem?.name;
            draft.id = firstItem?.id;
            draft.selected = firstItem?.selected;
          });
        }
      },
    },
  );

  const { run: getSuperAuthorityItemRun } = useRequest(
    () => {
      return getSuperAuthorityItem();
    },
    {
      manual: true,
      onSuccess: (res) => {
        setAdminList(res.data);
      },
    },
  );

  const { run: updatePermissionGroupRun } = useRequest(
    (type: EPermissionGroup) => {
      return addPermissionGroup({ ...permissionGroup, type });
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (!isUndefined(type)) {
      listPermissionGroupRun(type);
    }
    if (!authority) {
      getSuperAuthorityItemRun();
    }
  }, []);

  useUpdateEffect(() => {
    if (!isUndefined(type) && !isFirstRun && !isUndefined(type)) {
      updatePermissionGroupRun(type);
    } else {
      setIsFirstRun(false);
    }
  }, [permissionGroup]);

  const [open, setOpen] = useState<boolean>(false);
  const [groupTree, setGroupTree] = useState<IPeopleSelectRoleObj[]>();
  const [memberTree, setMemberTree] = useState<IPeopleSelectMemberObj>();

  const getTreeListRun = useCallback(
    () =>
      getTreeList().then((res) => {
        setGroupTree(res.data);
      }),
    [open],
  );

  const getMemberTreeListRun = useCallback(
    () =>
      getOrganization().then((res) => {
        setMemberTree(res.data);
      }),
    [open],
  );

  useEffect(() => {
    if (open) {
      getTreeListRun();
      getMemberTreeListRun();
    }
  }, [open]);

  return (
    <div className={styles.permission_card}>
      <div className={styles.title}>{title}</div>
      {subTitle && <div className={styles.subtitle}>{subTitle}</div>}
      <div
        className={
          authority ? styles.selector_wrapper : styles.selector_wrapper_no_auth
        }
        onClick={() => {
          if (authority) {
            setOpen(true);
          }
        }}
      >
        {authority ? (
          permissionGroup.selected ? (
            <div
              className={styles.management_people}
              style={{ cursor: 'pointer', marginBottom: '24px' }}
            >
              {map(selected, (item) => (
                <Tag>{item}</Tag>
              ))}
            </div>
          ) : (
            <div className={styles.selector_empty}>
              <PlusSquareOutlined style={{ marginRight: 6 }} />
              点击添加权限组人员
            </div>
          )
        ) : (
          <div
            className={styles.management_people}
            style={{ cursor: 'pointer', marginBottom: '24px' }}
          >
            {map(adminList, (item) => (
              <Tag>{item}</Tag>
            ))}
          </div>
        )}
      </div>
      <PeopleRoleSelect
        memberNodes={memberNodes}
        groupNodes={groupNodes}
        groupData={groupTree}
        memberData={memberTree}
        mode="modal"
        visible={open}
        onClose={() => {
          setOpen(false);
        }}
        onOk={(data: {
          memberNodes: SelectMember[];
          groupNodes: SelectRoleListItem[];
        }) => {
          const { memberNodes: _memberNodes, groupNodes: _groupNodes } = data;
          console.log('_memberNodes: ', _memberNodes);
          console.log('_groupNodes: ', _groupNodes);
          updatePermissionGroup((draft) => {
            draft.selected = {
              roleList: map(_groupNodes, (item) => {
                return {
                  id: item.id,
                  name: item.completeName ?? '',
                };
              }),
              personList: map(_memberNodes, (item) => {
                return {
                  pernr: item.id,
                  nameEn: item.completeName.split('-')[0],
                  nameZh: item.completeName.split('-')[1],
                };
              }),
            };
          });
          setOpen(false);
        }}
      />
    </div>
  );
};

export default PermissionCard;
