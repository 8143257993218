import React from 'react';
import styles from './index.less';
import { IEnterpriseMemberInfo } from '@/types/enterpriseServiceBackend/addMembers';
import { companyRoleMap } from '@/types';
import { Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface IMemberCardProps {
  index?: number;
  member: IEnterpriseMemberInfo;
  style?: React.CSSProperties;
  handleRemoveMember?: (uid: number) => void;
}

const MemberCard: React.FC<IMemberCardProps> = ({
  index,
  member,
  style,
  handleRemoveMember,
}) => {
  return (
    <div className={styles.memberItem} style={style}>
      <div className={styles.memberInfo}>
        <div className={styles.username}>
          <div>
            {index && <span>{index}.</span>}
            {member.username}
            <span className={styles.item_title} style={{ marginLeft: 8 }}>
              uid:{' '}
            </span>
            <span className={styles.item_value}>{member.uid}</span>
          </div>
          {handleRemoveMember && (
            <div>
              <DeleteOutlined
                className={styles.deleteIcon}
                onClick={() => handleRemoveMember?.(member.uid)}
              />
            </div>
          )}
        </div>
        <Space size={8}>
          <span className={styles.item_title}>手机号：</span>
          <span className={styles.item_value}>{member.mobile}</span>
          <span className={styles.item_title} style={{ marginLeft: 8 }}>
            角色：
          </span>
          <span className={styles.item_value}>
            {companyRoleMap[member.role]}
          </span>
          <span className={styles.item_title} style={{ marginLeft: 8 }}>
            姓名：
          </span>
          <span className={styles.item_value}>{member.realname || '--'}</span>
        </Space>
      </div>
    </div>
  );
};

export default MemberCard;
