import React, { useState } from 'react';
import { Radio, Form } from 'antd';
import type { RadioChangeEvent } from 'antd';
import Join from './join';
import styles from '../index.less';
import Create from './create';
import { EJoinType } from '@/types/enterpriseServiceBackend/addMembers';
import { useModel } from '@@/plugin-model/useModel';

const JoinEnterprise: React.FC = () => {
  const { form, mobile } = useModel('useAddMembersModel', (model) => ({
    form: model.form,
    mobile: model.mobile,
  }));
  const joinOptions = [
    { label: '加入已有企业', value: EJoinType.JOIN },
    { label: '创建新企业', value: EJoinType.CREATE },
  ];

  const joinType = Form.useWatch('joinType', form);

  const handleChange = (e: RadioChangeEvent) => {
    form.resetFields();
    form.setFieldValue('mobile', mobile);
    if (e.target.value === EJoinType.JOIN) {
      form.setFieldValue('joinType', EJoinType.JOIN);
    } else {
      form.setFieldValue('joinType', EJoinType.CREATE);
    }
  };

  return (
    <React.Fragment>
      <Form.Item name={'joinType'}>
        <Radio.Group
          onChange={handleChange}
          options={joinOptions}
          className={styles.enterprise_radio_group}
          buttonStyle={'solid'}
          optionType={'button'}
        />
      </Form.Item>
      {joinType === EJoinType.JOIN ? <Join /> : <Create />}
    </React.Fragment>
  );
};

export default JoinEnterprise;
