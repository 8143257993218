import React from 'react';
import styles from './index.less';
import { useModel } from '@@/plugin-model/useModel';
import MemberCard from '@/pages/enterpriseServiceBackend/deleteMembers/components/memberCard';
import {
  ICompanyInfo,
  IEnterpriseMemberInfo,
} from '@/types/enterpriseServiceBackend/addMembers';

type TSuccessProps = {
  companyInfo?: ICompanyInfo;
  memberList: IEnterpriseMemberInfo[];
};

const DeleteMembersSuccess: React.FC<TSuccessProps> = ({
  companyInfo,
  memberList,
}) => {
  return (
    <div className={styles.success_page}>
      <img
        src={'https://hwobs.fanruan.com/ui/color-success.png'}
        style={{ width: 200, height: 200 }}
      />
      <div className={styles.success_title}>移除企业成员成功</div>
      <div className={styles.success_subTitle}>
        请告知客户刷新页面或重新登录后查看
      </div>
      <div className={styles.infoCard}>
        <div className={styles.title}>目标企业：</div>
        <div className={styles.content}>
          <div className={styles.enterpriseInfo}>
            {companyInfo?.comname}{' '}
            <span className={styles.mvid}>（mvid: {companyInfo?.mvId}）</span>
          </div>
          <div className={styles.detail}>
            <span>
              成员数：
              {companyInfo?.memberCount === undefined
                ? 0
                : companyInfo?.memberCount - memberList.length}
            </span>
            <span>
              超管：{companyInfo?.adminUser?.username} (
              {companyInfo?.adminUser?.mobile})
            </span>
          </div>
        </div>
        {memberList.length > 0 && (
          <React.Fragment>
            <div className={styles.title} style={{ marginTop: 16 }}>
              移除的成员：
            </div>
            {memberList.map((member, index) => (
              <MemberCard
                style={{ marginBottom: 10, padding: 8 }}
                key={member.uid}
                member={member}
                index={index + 1}
              />
            ))}
          </React.Fragment>
        )}
      </div>
      {/* {memberList.length > 0 && ( */}
      {/*   <div className={styles.memberList}> */}
      {/*     <div className={styles.content}> */}
      {/*       {memberList.map((member, index) => ( */}
      {/*         <MemberCard key={member.uid} member={member} index={index + 1} /> */}
      {/*       ))} */}
      {/*     </div> */}
      {/*   </div> */}
      {/* )} */}
    </div>
  );
};

export default DeleteMembersSuccess;
