import styles from '@/pages/enterpriseServiceBackend/addMembers/index.less';
import AddMembersForm from '@/pages/enterpriseServiceBackend/addMembers/form';
import { NoAccessPage } from '@digit-fe/digit-component';
import { useModel } from '@@/plugin-model/useModel';
import React, { useState } from 'react';
import { useRequest } from '@@/plugin-request/request';
import { getAddMemberAuthority } from '@/utils/requests/requests_addMembers';
import { Alert, Steps } from 'antd';
import BottomButtons from '@/pages/enterpriseServiceBackend/addMembers/components/bottomButtons';
import AddMembersNewForm from '@/pages/enterpriseServiceBackend/addMembers/form/newIndex';
import { InfoCircleOutlined } from '@ant-design/icons';
import AddMembersSuccess from '@/pages/enterpriseServiceBackend/addMembers/success';
import useGetUserInfo from '@/hooks/useGetUserInfo';

export default function AddMembersPage() {
  const { initialState } = useModel('@@initialState');
  const userInfo = useGetUserInfo();
  const { isFrAccount } = initialState;
  const { addMembersStep } = useModel('useAddMembersModel', (model) => ({
    addMembersStep: model.addMembersStep,
  }));

  return (
    <div className={styles.add_members}>
      {userInfo.permission.canAddMember || userInfo.permission.admin ? (
        <div className={styles.add_members_content}>
          <div className={styles.wrapper}>
            <Alert
              type={'info'}
              message={'请先和客户核实身份后再进行操作，将会记录操作日志'}
              className={styles.alert_info}
              showIcon={true}
              icon={<InfoCircleOutlined className={styles.alert_icon} />}
            />
            <Steps
              current={addMembersStep}
              size={'small'}
              responsive={false}
              className={styles.steps}
              items={[
                { title: '查找账号' },
                { title: '加入企业' },
                { title: '操作完成' },
              ]}
            />
            <AddMembersNewForm />
            {addMembersStep === 2 && <AddMembersSuccess />}
          </div>
          <BottomButtons />
        </div>
      ) : (
        <NoAccessPage
          switchAccountHref={`${process.env.ID_URL}/login/token/logout?referrer=${window.location.href}`}
          isFrAccount={isFrAccount}
        />
      )}
    </div>
  );
}
