import { requestGet, requestPost } from '@/utils/request';
import {
  IAccountInfo,
  ICompanyInfo,
  ICrmCustomerInfo,
  IEnterpriseMemberInfo,
} from '@/types/enterpriseServiceBackend/addMembers';
import { TApi } from '@/types/common/interface';

export interface IFindCompanyByName {
  records: ICompanyInfo[];
  total: number;
}

export function validateMobile(params: string): Promise<TApi<IAccountInfo>> {
  return requestGet(
    `/fcoin/api/company/details/by/user?key=${params}&type=MOBILE&limit=1`,
    {},
  );
}

export function getCrmCompaniesByMobile(
  mobile: string,
): Promise<TApi<ICompanyInfo[]>> {
  return requestGet(
    `/fcoin/api/company/search/crm/company/by/crm/comids?mobile=${mobile}`,
    {},
  );
}

export function findCompanyWithCrmByName(
  params: any,
): Promise<TApi<IFindCompanyByName>> {
  return requestPost(`/fcoin/api/company/search/company/list/with/crm`, params);
}

export function findCompanyByName(
  params: any,
): Promise<TApi<IFindCompanyByName>> {
  return requestPost(`/fcoin/api/company/search/company/list`, params);
}

export function findCrmCustomers(
  params: any,
): Promise<TApi<ICrmCustomerInfo[]>> {
  return requestGet(`/fcoin/api/company/search/crm/company`, params);
}

export function getMemberList(
  params: any,
): Promise<TApi<IEnterpriseMemberInfo[]>> {
  return requestGet(`/fcoin/api/company/find/company/member`, params);
}

export function removeMembers(mvId: number, uids: string[]) {
  return requestPost(`/fcoin/api/company/delete/member?mvId=${mvId}`, uids);
}
