// 用户在企业内的角色-枚举
export enum ECompanyRole {
  // 成员
  MEMBER,
  // 超管
  SUPER_ADMIN,
  // 子管理员
  ADMIN,
}

// 用户在企业内的角色
export const companyRoleMap: { [key in ECompanyRole]: string } = {
  [ECompanyRole.MEMBER]: '成员',
  [ECompanyRole.SUPER_ADMIN]: '超级管理员',
  [ECompanyRole.ADMIN]: '子管理员',
};

// 企业合作认证审核状态-枚举
export enum ECompanyReviewState {
  // 审核未通过
  FAILED = -1,
  // 待审核
  WAITING,
  // 审核通过
  PASSED,
}

// 企业合作认证方式-枚举
export enum ECompanyCooperateType {
  // 服务凭证
  CERTIFICATION = 1,
  // 帆软合同
  CONTRACT,
  // 销售跟进
  SALER_FOLLOW,
  // 线下合同
  OFFLINE_CONTRACT,
}

// 企业合作认证方式
export const companyCooperateTypeMap: {
  [key in ECompanyCooperateType]: string;
} = {
  [ECompanyCooperateType.CERTIFICATION]: '服务凭证',
  [ECompanyCooperateType.CONTRACT]: '帆软合同申请',
  [ECompanyCooperateType.SALER_FOLLOW]: '销售跟进写入',
  [ECompanyCooperateType.OFFLINE_CONTRACT]: '线下合同写入',
};
