import React from 'react';
import { Alert } from 'antd';
import DeleteMembersForm from './form';
import styles from './index.less';
import DeleteMembersSuccess from './success';
import useGetUserInfo from '@/hooks/useGetUserInfo';
import { NoAccessPage } from '@digit-fe/digit-component';
import { useModel } from '@@/plugin-model/useModel';

const DeleteMembers: React.FC = () => {
  const { initialState } = useModel('@@initialState');
  const userInfo = useGetUserInfo();
  const { isFrAccount } = initialState;
  return (
    <div className={styles.wrapper}>
      {userInfo.permission.canRemoveMember || userInfo.permission.admin ? (
        <div className={styles.container}>
          <DeleteMembersForm />
        </div>
      ) : (
        <NoAccessPage
          switchAccountHref={`${process.env.ID_URL}/login/token/logout?referrer=${window.location.href}`}
          isFrAccount={isFrAccount}
        />
      )}
    </div>
  );
};

export default DeleteMembers;
