// 权限组
export enum EPermissionGroup {
  /**
   * 谁可以使用“向企业添加成员”
   */
  ADD_MEMBER = 0,

  /**
   * 谁可以使用“移除企业成员”功能
   */
  REMOVE_MEMBER = 1,

  /**
   * 谁可以使用“企业合并”功能
   */
  MERGE_COMPANY = 2,
}

/**
 * 权限组
 */
export type TPermissionGroup = {
  id?: string;
  /**
   * 权限组名称
   */
  name: string;
  /**
   * 权限组类型
   */
  type: EPermissionGroup;
  /**
   * 权限组角色
   */
  selected?: TSelected;
  /**
   * 权限组权限
   */
  permission?: never;
  /**
   * 关联id
   */
  relationId?: string;
};

export type TSelected = {
  /**
   * 角色
   */
  roleList: TPermissionRole[];
  /**
   * 人员
   */
  personList: TPermissionPerson[];
};

export type TPermissionRole = {
  /**
   * 角色id
   */
  id: number;
  /**
   * 角色名称
   */
  name: string;
};

export type TPermissionPerson = {
  pernr: string; // 工号
  nameEn: string;
  nameZh: string;
};
