import { IRouteComponentProps, useModel, useRequest } from 'umi';
import styles from '@/global.less';
import { Breadcrumb } from 'antd';
import ProductResourceConfiguration from '@/pages/productResource/pageConfiguration';
import useGetUserInfo from '@/hooks/useGetUserInfo';
import { getCurrentUserInfo } from '@/utils/requests/requests_utils';
import { useEffect, useState } from 'react';
import { NoAccessPage } from '@digit-fe/digit-component';

export default function ProductResourceManagePage(props: IRouteComponentProps) {
  const { nameEn } = useGetUserInfo();
  const [currentUserInfo, setCurrentUserInfo] = useState<any>();
  const { initialState } = useModel('@@initialState');
  const { isFrAccount } = initialState;
  const [isHasAccess, setIsHasAccess] = useState<boolean>(true);

  const getCurrentUserInfoRequest = useRequest(
    () => {
      return getCurrentUserInfo({ type: 'MY_FANRUAN' });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setCurrentUserInfo(res);
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    nameEn && getCurrentUserInfoRequest.run();
  }, [nameEn]);

  return (
    <div className={styles.content_wrapper}>
      <div
        style={{
          width: '1200px',
        }}
      >
        <div>
          {isFrAccount && isHasAccess ? (
            <>
              <Breadcrumb>
                <Breadcrumb.Item href="/resource">产品资料</Breadcrumb.Item>
                <Breadcrumb.Item>产品资料配置</Breadcrumb.Item>
              </Breadcrumb>
              <ProductResourceConfiguration />
            </>
          ) : (
            <NoAccessPage
              switchAccountHref={`${process.env.ID_URL}/login/token/logout?referrer=${window.location.href}`}
              isFrAccount={isFrAccount}
            />
          )}
        </div>
      </div>
    </div>
  );
}
