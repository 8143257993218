import React, { useEffect, useMemo } from 'react';
import styles from '@/pages/enterpriseServiceBackend/addMembers/index.less';
import { Button, Form, Tooltip, Modal } from 'antd';
import { useModel } from '@@/plugin-model/useModel';
import { EJoinType } from '@/types/enterpriseServiceBackend/addMembers';
import { companyRoleMap, ECompanyRole } from '@/types';
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useRequest } from '@@/plugin-request/request';
import { addMember, createCompany } from '@/utils/requests/requests_addMembers';

interface ITips {
  showTooltip: boolean;
  showTip: boolean;
  tips: string;
  buttonDisabled: boolean;
}

const BottomButtons: React.FC<{}> = () => {
  const {
    addMembersStep,
    goToNextStep,
    goToPrevStep,
    form,
    mobileValid,
    manualMvId,
    customerAccountInfo,
    customerCompanyInfo,
    mobile,
    selectCompany,
    setNewCompanyInfo,
    clearFormAndInfo,
    selectCrmCompany,
  } = useModel('useAddMembersModel', (model) => ({
    addMembersStep: model.addMembersStep,
    goToNextStep: model.goToNextStep,
    goToPrevStep: model.goToPrevStep,
    form: model.form,
    mobileValid: model.mobileValid,
    manualMvId: model.manualMvId,
    customerAccountInfo: model.customerAccountInfo,
    customerCompanyInfo: model.customerCompanyInfo,
    mobile: model.mobile,
    selectCompany: model.selectCompany,
    setNewCompanyInfo: model.setNewCompanyInfo,
    clearFormAndInfo: model.clearFormAndInfo,
    selectCrmCompany: model.selectCrmCompany,
  }));

  const joinType = Form.useWatch('joinType', form);
  // 加入企业 监听值
  const mvid = Form.useWatch('mvid', form);
  // 创建企业监听值
  const customerName = Form.useWatch('customerName', form);
  const crmCustomer = Form.useWatch('crmCustomer', form);
  const comname = Form.useWatch('comname', form);
  const role: ECompanyRole = Form.useWatch('role', form);

  const { run: addMemberRun } = useRequest(
    (value) => {
      return addMember(JSON.stringify(value));
    },
    {
      manual: true,
      onSuccess: (res) => {
        goToNextStep();
      },
    },
  );

  const { run: createCompanyRun } = useRequest(
    (value) => {
      return createCompany(JSON.stringify(value));
    },
    {
      manual: true,
      onSuccess: (res) => {
        setNewCompanyInfo((prev: any) => ({ ...prev, mvId: res?.comId }));
        goToNextStep();
      },
    },
  );

  const buttonTips: ITips = useMemo(() => {
    if (joinType === EJoinType.JOIN) {
      let tempMvId = mvid;
      if (mvid === 'manual') {
        tempMvId = manualMvId;
      }
      if (tempMvId === undefined) {
        return {
          showTooltip: true,
          showTip: false,
          tips: '请选择要加入的企业',
          buttonDisabled: true,
        };
      }
      if (customerCompanyInfo === undefined) {
        return {
          showTooltip: false,
          showTip: false,
          tips: '请选择要加入的企业',
          buttonDisabled: false,
        };
      }
      if (tempMvId === customerCompanyInfo?.mvId) {
        return {
          showTooltip: false,
          showTip: true,
          buttonDisabled:
            customerCompanyInfo?.role === ECompanyRole.SUPER_ADMIN,
          tips:
            customerCompanyInfo?.role === ECompanyRole.SUPER_ADMIN
              ? '客户已是所选企业超管，不可重复加入'
              : '客户已加入所选企业，继续操作将仅修改客户角色',
        };
      } else {
        return {
          showTooltip: false,
          showTip: true,
          buttonDisabled: false,
          tips:
            customerCompanyInfo?.role === ECompanyRole.SUPER_ADMIN
              ? '客户是其他企业超管，继续操作将解散之前企业并加入新企业'
              : '客户已加入其他企业，继续操作将退出并加入新企业',
        };
      }
    } else {
      if (!customerName || !crmCustomer || !comname) {
        return {
          showTooltip: true,
          showTip: false,
          buttonDisabled: true,
          tips: '请填写必填项',
        };
      }
      if (customerCompanyInfo?.mvId !== undefined) {
        return {
          showTooltip: false,
          showTip: true,
          buttonDisabled: false,
          tips:
            customerCompanyInfo?.role === ECompanyRole.SUPER_ADMIN
              ? '客户是其他企业超管，继续操作将解散之前企业并加入新企业'
              : '客户已加入其他企业，继续操作将退出并加入新企业',
        };
      }
      return {
        showTooltip: false,
        showTip: false,
        buttonDisabled: false,
        tips: '',
      };
    }
  }, [mvid, manualMvId, joinType, customerName, crmCustomer, comname]);

  // 二次确认弹窗
  const handleJoinConfirm = () => {
    // 显示二次确认弹框
    Modal.confirm({
      title: '确认向企业添加成员吗？',
      icon: <QuestionCircleOutlined />,
      content: (
        <div>
          目标企业：{selectCompany?.comname}（mvid:
          {selectCompany?.mvId}）
          <br />
          目标成员手机号：{form.getFieldValue('location')} {mobile}
          <br />
          备注名：{form.getFieldValue('remark')}
          <br />
          成员角色：{role !== undefined ? companyRoleMap[role] : '--'}
        </div>
      ),
      okText: '确认',
      cancelText: '取消',
      onOk() {
        setNewCompanyInfo({
          ...selectCompany,
          mobile,
          role,
          location: form.getFieldValue('location'),
          joinType,
        });
        addMemberRun({
          mobile,
          location: form.getFieldValue('location'),
          mvid: selectCompany?.mvId,
          uid: customerAccountInfo?.uid,
          remark: form.getFieldValue('remark'),
          role,
        });
      },
      onCancel() {
        console.log('Cancel'); // 用户点击“取消”时的操作
      },
    });
  };

  const handleCreateConfirm = () => {
    const crmComName = selectCrmCompany?.crmComName;
    const customerName = form.getFieldValue('customerName');
    const location = form.getFieldValue('location');
    const comname = form.getFieldValue('comname');
    // 显示二次确认弹框
    Modal.confirm({
      title: '确认创建新企业吗？',
      icon: <QuestionCircleOutlined />,
      content: (
        <div>
          关联CRM客户：{crmComName}
          <br />
          客户姓名：{customerName}
          <br />
          客户手机号：{location} {mobile}
          <br />
          企业名称：{comname}
        </div>
      ),
      okText: '确认',
      cancelText: '取消',
      onOk() {
        setNewCompanyInfo({
          ...selectCompany,
          mobile,
          role: ECompanyRole.SUPER_ADMIN,
          location,
          joinType,
          comname,
        });
        createCompanyRun({
          comname,
          crmcomid: selectCrmCompany?.crmComId,
          adminMobile: mobile,
          location,
          adminName: customerName,
          contractSource: 3,
        });
      },
      onCancel() {
        console.log('Cancel'); // 用户点击“取消”时的操作
      },
    });
  };

  return (
    <div className={styles.bottom_buttons_wrapper}>
      {addMembersStep === 0 && (
        <div className={styles.bottom_button}>
          {mobileValid ? (
            <Button type={'primary'} onClick={goToNextStep}>
              下一步
            </Button>
          ) : (
            <Tooltip title={'请正确填写客户手机号'} style={{ width: '100%' }}>
              <Button disabled={true} type={'primary'} onClick={goToNextStep}>
                下一步
              </Button>
            </Tooltip>
          )}
        </div>
      )}
      {addMembersStep === 1 && (
        <div>
          {buttonTips.showTip && (
            <div className={styles.tips}>
              <ExclamationCircleOutlined style={{ marginRight: 8 }} />
              <div>{buttonTips.tips}</div>
            </div>
          )}
          <div className={styles.bottom_buttons}>
            <Button onClick={goToPrevStep}>上一步</Button>
            {buttonTips.showTooltip ? (
              <Tooltip title={buttonTips.tips}>
                <Button
                  type={'primary'}
                  onClick={
                    joinType === EJoinType.JOIN
                      ? handleJoinConfirm
                      : handleCreateConfirm
                  }
                  disabled={true}
                >
                  {joinType === EJoinType.JOIN ? '确认加入' : '确认创建'}
                </Button>
              </Tooltip>
            ) : (
              <Button
                type={'primary'}
                onClick={
                  joinType === EJoinType.JOIN
                    ? handleJoinConfirm
                    : handleCreateConfirm
                }
                disabled={buttonTips.buttonDisabled}
              >
                {joinType === EJoinType.JOIN ? '确认加入' : '确认创建'}
              </Button>
            )}
          </div>
        </div>
      )}
      {addMembersStep === 2 && (
        <div className={styles.bottom_button}>
          <Button onClick={clearFormAndInfo}>继续为其他客户操作</Button>
        </div>
      )}
    </div>
  );
};

export default BottomButtons;
