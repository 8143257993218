// 向企业添加成员步骤
import {
  ECompanyCooperateType,
  ECompanyReviewState,
  ECompanyRole,
} from '@/types';

// 用户所在公司信息
export interface ICompanyInfo {
  mvId: number;
  comname: string;
  role?: ECompanyRole;
  // 当前用户角色
  companyRole?: ECompanyRole;
  // 合作认证审核状态
  state: ECompanyReviewState;
  // 合作认证方式
  type: ECompanyCooperateType;
  // 经办人
  assignReviewerName?: string;
  // 申请时间
  entryTime: string;
  // 审核时间
  reviewTime: string;
  // 关联 CRM 客户
  crmComId?: string;
  crmComName?: string;
  // 成员数
  memberCount?: number;
  adminUser?: TUser;

  [key: string]: any;
}

// 客户 CRM 名片信息
export interface ICrmCardInfo {
  comId: string;
  crmComName: string;
}

// 查询账号信息
export interface IAccountInfo {
  uid?: number;
  username?: string;
  realName?: string;
  mobile?: string;
  // 客户 CRM 名片信息
  crmCardList?: ICrmCardInfo[];
  // 公司信息
  comInfo?: ICompanyInfo;
  // 手机号校验结果
  valid?: boolean;

  [key: string]: any;
}

// CRM 关联客户信息
export interface ICrmCustomerInfo {
  crmComId: string;
  crmComName: string;
  // 销售
  crmComSalesman: string;
  // 售前
  crmComPreSales: string;
  // 状态
  status: string;
}

// 企业成员信息
export interface IEnterpriseMemberInfo {
  username: string;
  uid: number;
  mobile: string;
  role: ECompanyRole;
  realname: string;
}

// 加入企业/创建企业
export enum EJoinType {
  JOIN = 'join',
  CREATE = 'create',
}

export type TUser = {
  id: number;
  mobile: string;
  username: string;
  realname: string;
  [key: string]: any;
};
