import React, { useEffect, useState } from 'react';
import { Empty, Form, Input, Select } from 'antd';
import styles from './index.less';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { findCrmCustomers } from '@/services/enterpriseServiceBackend';
import { ICrmCustomerInfo } from '@/types/enterpriseServiceBackend/addMembers';
import debounce from 'lodash/debounce';
import { useModel } from '@@/plugin-model/useModel';
import { escapeRegExp } from 'lodash';

const Create: React.FC = () => {
  const { form, mobile, customerAccountInfo, setSelectCrmCompany } = useModel(
    'useAddMembersModel',
    (model) => ({
      form: model.form,
      mobile: model.mobile,
      customerAccountInfo: model.customerAccountInfo,
      setSelectCrmCompany: model.setSelectCrmCompany,
    }),
  );
  const [crmCustomers, setCrmCustomers] = React.useState<ICrmCustomerInfo[]>(
    [],
  );
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [isInitialSearch, setIsInitialSearch] = React.useState(true);

  // 请求数据的函数
  const fetchCrmCustomers = async (params: {
    comName?: string;
    mobile?: string;
  }) => {
    const res = await findCrmCustomers(params);
    const customers = res.data || [];
    setCrmCustomers(customers);

    // 如果是手机号查询且只返回一条数据，自动选中
    if (params.mobile && !params.comName && customers.length === 1) {
      const customer = customers[0];
      form.setFieldValue('crmCustomer', customer.crmComId);
      form.setFieldValue('comname', customer.crmComName);
    }
  };

  // 初始加载时根据 mobile 请求数据
  const { loading: initialLoading } = useRequest(
    () => fetchCrmCustomers({ mobile }),
    {
      ready: !!mobile,
      onError: () => {
        setCrmCustomers([]);
      },
    },
  );

  // 搜索时请求数据
  const { loading: searchLoading, run } = useRequest(
    (keyword: string) => fetchCrmCustomers({ mobile, comName: keyword }),
    {
      manual: true,
      onError: () => {
        setCrmCustomers([]);
      },
    },
  );

  const debouncedSearch = React.useMemo(
    () =>
      debounce((value: string) => {
        run(value);
      }, 300),
    [run],
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (value) {
      setIsInitialSearch(false);
      debouncedSearch(value);
    } else {
      setIsInitialSearch(true);
      fetchCrmCustomers({ mobile });
    }
  };

  // 高亮匹配文字
  const highlightText = (text: string) => {
    if (!searchValue || !text) return text;
    const searchValueEscaped = escapeRegExp(searchValue);
    const regex = new RegExp(`(${searchValueEscaped})`, 'gi');
    return (
      <>
        {text.split(regex).map((part, i) => (
          <React.Fragment key={i}>
            {part === searchValue ? (
              <span style={{ color: '#fa8c16' }}>{part}</span>
            ) : (
              part
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  const dropdownRender = (menu: React.ReactElement) => (
    <div className={styles.dropdown_container}>
      {isInitialSearch && (
        <div className={styles.search_tip}>
          根据手机号匹配到客户，如果不对可输入公司名称搜索
        </div>
      )}
      {menu}
      <div className={styles.dropdown_footer}>
        <div className={styles.dropdown_tip}>
          为保证响应速度，crm仅返回前10个有效客户
        </div>
        <div className={styles.dropdown_tip}>
          如无匹配结果请输入准确公司名称
        </div>
      </div>
    </div>
  );

  const crmCustomer = Form.useWatch('crmCustomer');
  useEffect(() => {
    if (crmCustomer) {
      const crmComInfo = crmCustomers.find((c) => c.crmComId === crmCustomer);
      form.setFieldValue('comname', crmComInfo?.crmComName);
      setSelectCrmCompany(crmComInfo);
    }
  });

  const [nameEditable, setNameEditable] = useState<boolean>(true);
  useEffect(() => {
    if (customerAccountInfo?.realName) {
      setNameEditable(false);
      form.setFieldValue('customerName', customerAccountInfo.realName);
    }
  }, []);

  return (
    <React.Fragment>
      <Form.Item
        label={'客户姓名'}
        name={'customerName'}
        rules={[{ required: true, message: '请输入客户姓名' }]}
        className={styles.form_item_space}
      >
        <Input
          placeholder="请输入客户姓名"
          maxLength={20}
          showCount
          disabled={!nameEditable}
        />
      </Form.Item>

      <Form.Item
        label={'关联CRM客户'}
        name={'crmCustomer'}
        className={styles.form_item_space}
        rules={[{ required: true, message: '请选择CRM中客户' }]}
      >
        <Select
          showSearch
          placeholder={'请选择CRM中客户'}
          defaultActiveFirstOption={false}
          showArrow={true}
          filterOption={false}
          onSearch={handleSearch}
          notFoundContent={
            initialLoading || searchLoading ? (
              '加载中...'
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'未找到客户，建议输入准确公司名称搜索'}
              />
            )
          }
          style={{ width: '100%' }}
          dropdownRender={dropdownRender}
          optionLabelProp="title"
          options={crmCustomers.map((item) => ({
            label: (
              <div>
                <div className={styles.ellipsis_crm}>
                  {highlightText(item.crmComName)}
                </div>
                <div className={styles.customer_info}>
                  <span>销售：{item.crmComSalesman}</span>
                  <span>售前：{item.crmComPreSales}</span>
                </div>
              </div>
            ),
            value: item.crmComId,
            title: item.crmComName,
          }))}
        />
      </Form.Item>

      <Form.Item
        label={'企业名称'}
        name={'comname'}
        className={styles.form_item_space}
        rules={[{ required: true, message: '请输入企业名称' }]}
        extra={
          <div className={styles.extra_tip}>
            <ExclamationCircleOutlined />
            <div>名称一定要规范，会展示给客户</div>
          </div>
        }
      >
        <Input placeholder="请输入企业名称" maxLength={100} showCount />
      </Form.Item>

      <div className={styles.description}>
        <div>说明：</div>
        <ol>
          <li>
            创建企业后，客户可以使用该手机号作为超级管理员登录{' '}
            <a href="https://service.fanruan.com" target={'_blank'}>
              帆软服务平台
            </a>{' '}
            获取技术支持服务；
          </li>
          <li>
            管理员也可{' '}
            <a href="https://fanruanclub.com/#/enterprise" target={'_blank'}>
              复制成员邀请链接
            </a>
            ，邀请同事共享技术支持服务；
          </li>
          <li>
            为确保客户使用规范，请尽量以部门、项目为单位创建企业，只给负责人身份的人创建企业，其他成员由管理员邀请和维护
          </li>
          <li>
            <a
              href="https://help.fanruan.com/finereport/doc-view-3934.html"
              target={'_blank'}
            >
              了解帆软服务平台
            </a>
            ，如有困难或建议请联系elaine-姚颖。
          </li>
        </ol>
      </div>
    </React.Fragment>
  );
};

export default Create;
