import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Upload,
  Button,
  message,
  Select,
  Alert,
  Modal,
  Empty,
} from 'antd';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import styles from '../index.less';
import {
  ICompanyInfo,
  IEnterpriseMemberInfo,
} from '@/types/enterpriseServiceBackend/addMembers';
import { useRequest } from 'ahooks';
import {
  getMemberList,
  removeMembers,
} from '@/services/enterpriseServiceBackend';
import MemberCard from '@/pages/enterpriseServiceBackend/deleteMembers/components/memberCard';
import EnterpriseChoose from '@/components/enterpriseChoose';
import {
  calFileUploadParams,
  getCoverBase64,
  getFileNameAndType,
  randomStr,
} from '@/utils';
import { RcFile } from 'antd/es/upload';
import axios from 'axios';
import { submitFile } from '@/utils/requests/requests_utils';
import { useWebTrack } from '@digit-fe/digit-component';
import DeleteMembersSuccess from '@/pages/enterpriseServiceBackend/deleteMembers/success';
import { companyRoleMap, ECompanyRole } from '@/types';
import useGetUserInfo from '@/hooks/useGetUserInfo';
import { escapeRegExp } from 'lodash';
import OriginEnterpriseCard from '@/pages/enterpriseServiceBackend/deleteMembers/components/originEnterpriseCard';

const { TextArea } = Input;
const { Option } = Select;
const { Dragger } = Upload;

const DeleteMembersForm: React.FC = () => {
  const [form] = Form.useForm();
  const [memberList, setMemberList] = useState<IEnterpriseMemberInfo[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<
    IEnterpriseMemberInfo[]
  >([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const { sendEvent } = useWebTrack();
  const mvid = Form.useWatch('mvid', form);
  const [submitFinish, setSubmitFinish] = useState<boolean>(false);
  const userInfo = useGetUserInfo();
  const [memberSearchValue, setMemberSearchValue] = useState<
    string | undefined
  >();
  const [memberSearchOpen, setMemberSearchOpen] = useState<boolean>(false);
  const isDropDownMustActiveRef = useRef(false);
  const setIsDropDownMustActive = (value: boolean) => {
    isDropDownMustActiveRef.current = value;
  };

  const { run: getMemberListRun } = useRequest(
    (key?: string) => {
      return getMemberList({
        mvId: mvid,
        key,
      });
    },
    {
      manual: true,
      debounceWait: 500,
      onSuccess: (res) => {
        console.log('res: ', res);
        setMemberList(res.data);
      },
    },
  );

  useEffect(() => {
    if (!submitFinish) {
      setSelectedMembers([]);
      form.setFieldValue('members', []);
    }
    if (mvid !== undefined) {
      getMemberListRun('');
    }
  }, [mvid]);

  const handleSubmit = async (values: any) => {
    try {
      // 处理表单提交逻辑
      console.log('Form values:', values);
      message.success('操作成功');
    } catch (error) {
      message.error('操作失败');
    }
  };

  const uploadProps = {
    name: 'file',
    accept: '.jpg,.jpeg,.png',
    maxCount: 10,
    multiple: true,
    fileList,
    showUploadList: false,
  };

  const handleMemberSelect = (uid: number, isAdd: boolean) => {
    setIsDropDownMustActive(true);
    const targetMember = memberList.find((m) => m.uid === uid);

    if (!targetMember) return; // 成员不存在时直接返回

    setSelectedMembers((prevState) => {
      const existingUIDs = new Set(prevState.map((m) => m.uid));

      if (isAdd && !existingUIDs.has(uid)) {
        // 新增时检查是否存在
        return [...prevState, targetMember];
      } else if (!isAdd && existingUIDs.has(uid)) {
        // 移除时检查是否存在
        return prevState.filter((m) => m.uid !== uid);
      }

      return prevState; // 不需要修改时直接返回原数组
    });
  };

  useEffect(() => {
    form.setFieldValue(
      'members',
      selectedMembers?.map((m) => m.uid),
    );
  }, [selectedMembers]);

  const handleRemoveMember = (uid: number) => {
    const newSelected = selectedMembers.filter((m) => m.uid !== uid);
    setSelectedMembers(newSelected);
    form.setFieldValue(
      'members',
      newSelected.map((m) => m.uid),
    );
  };

  // 添加高亮文本的辅助函数
  const highlightText = (text: string) => {
    if (!memberSearchValue || !text) return text;
    const searchValueEscaped = escapeRegExp(memberSearchValue);
    const regex = new RegExp(`(${searchValueEscaped})`, 'gi');
    return (
      <>
        {text.split(regex).map((part, i) => (
          <React.Fragment key={i}>
            {part === memberSearchValue ? (
              <span style={{ color: '#fa8c16' }}>{part}</span>
            ) : (
              part
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  useEffect(() => {
    form.setFieldValue(
      'communication',
      fileList?.map((file) => file.url),
    );
  }, [fileList]);

  const [removeOriginCompany, setRemoveOriginCompany] =
    useState<ICompanyInfo>();
  const handleMvidChange = (mvid: number, companyInfo: ICompanyInfo) => {
    form.setFieldValue('mvid', mvid);
    setRemoveOriginCompany(companyInfo);
  };

  const { run: removeMembersRun } = useRequest(
    () => {
      return removeMembers(
        mvid,
        selectedMembers?.map((member) => member.uid.toString()) || [],
      );
    },
    {
      manual: true,
      onSuccess: (res) => {
        const fileListWithoutData = fileList.map((file) => {
          const { data, ...rest } = file;
          return rest;
        });
        // 发送事件
        sendEvent({
          uid: userInfo?.uid || '',
          name: '移除企业成员',
          operator: `${userInfo?.nameEn}-${userInfo?.nameZh}`,
          ...form,
          communication: fileListWithoutData,
        });
        setSubmitFinish(true);
      },
    },
  );

  const handleRemoveConfirm = () => {
    // 显示二次确认弹框
    Modal.confirm({
      title: '确认要移除该企业下的成员吗？此操作无法撤销。',
      icon: <QuestionCircleOutlined />,
      content: (
        <div>
          <div className={styles.confirm_modal_q}>目标企业：</div>
          <div className={styles.confirm_modal_v}>
            {removeOriginCompany?.comname}（mvid: {removeOriginCompany?.mvId}）
          </div>
          <div className={styles.confirm_modal_q}>移除的成员：</div>
          <div className={styles.confirm_modal_v}>
            {selectedMembers.map((member, index) => (
              <div>
                {index + 1}.{member.username}（uid：{member.uid}）
                {companyRoleMap[member.role]}
              </div>
            ))}
          </div>
        </div>
      ),
      okText: '确认',
      cancelText: '取消',
      onOk() {
        removeMembersRun();
      },
    });
  };

  return (
    <React.Fragment>
      <div className={styles.center_wrapper}>
        {submitFinish ? (
          <DeleteMembersSuccess
            companyInfo={removeOriginCompany}
            memberList={selectedMembers}
          />
        ) : (
          <React.Fragment>
            <Alert
              message="可将客户企业下指定成员移除。请上传和客户沟通的截图，得到客户授权后再操作，将会记录操作日志"
              type="info"
              showIcon
              className={styles.alert}
            />
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              className={styles.form}
            >
              <Form.Item
                label="请选择企业"
                name="mvid"
                rules={[{ required: true, message: '请选择企业' }]}
              >
                <EnterpriseChoose
                  style={{ width: '100%' }}
                  withCrmInfo={false}
                  value={removeOriginCompany?.mvId}
                  //@ts-ignore
                  onCompanyInfoChange={handleMvidChange}
                />
                {removeOriginCompany && (
                  <OriginEnterpriseCard
                    style={{ marginTop: 10 }}
                    company={removeOriginCompany}
                    handleRemoveEnterprise={() => {
                      setSelectedMembers([]);
                      setMemberList([]);
                      setMemberSearchValue(undefined);
                      form.setFieldValue('mvid', undefined);
                      setRemoveOriginCompany(undefined);
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="选择要移除的成员"
                name={'members'}
                rules={[{ required: true, message: '请选择要移除的成员' }]}
              >
                <div
                  className={styles.memberSelectWrapper}
                  style={{ position: 'relative' }}
                >
                  <Input
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: 36,
                      zIndex: 100,
                    }}
                    value={memberSearchValue}
                    onChange={(e) => {
                      const val = e.target.value;
                      setMemberSearchOpen(true);
                      setMemberSearchValue(e.target.value);
                      if (val !== undefined && val !== '') {
                        getMemberListRun(val);
                      } else {
                        setMemberList([]);
                      }
                    }}
                    onFocus={() => {
                      setMemberSearchOpen(true);
                      if (
                        memberSearchValue !== undefined &&
                        memberSearchValue !== ''
                      ) {
                        getMemberListRun(memberSearchValue);
                      } else {
                        setMemberList([]);
                      }
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        if (!isDropDownMustActiveRef.current) {
                          setMemberSearchOpen(false);
                        }
                      }, 100);
                    }}
                    disabled={mvid === undefined}
                    placeholder={
                      mvid === undefined
                        ? '请先选择企业'
                        : '输入uid、手机号或昵称搜索企业下成员'
                    }
                  />
                  <Select
                    mode="multiple"
                    disabled={mvid === undefined}
                    placeholder={
                      mvid === undefined
                        ? '请先选择企业'
                        : '输入uid、手机号或昵称搜索企业下成员'
                    }
                    optionLabelProp="label"
                    className={styles.memberSelect}
                    popupClassName={styles.memberSelectPopup}
                    onDeselect={(value) => {
                      handleMemberSelect(value, false);
                    }}
                    onSelect={(value) => {
                      handleMemberSelect(value, true);
                    }}
                    value={selectedMembers.map((item) => item.uid)}
                    searchValue={memberSearchValue}
                    open={memberSearchOpen}
                    maxTagCount={0}
                    showSearch
                    onSearch={(value) => {
                      getMemberListRun(value);
                    }}
                    onBlur={() => {
                      setIsDropDownMustActive(false);
                      setMemberSearchOpen(false);
                    }}
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                          <div style={{ textAlign: 'center' }}>
                            未找到结果，换个关键词试试
                          </div>
                        }
                      />
                    }
                    filterOption={(input, option) => {
                      const member = memberList.find(
                        (m) => m.uid === option?.value,
                      );
                      if (!member || !input) return true;

                      const searchText = input.toLowerCase();
                      return (
                        member.username?.toLowerCase().includes(searchText) ||
                        member.realname?.toLowerCase().includes(searchText) ||
                        member.uid?.toString().includes(searchText) ||
                        member.mobile?.includes(searchText)
                      );
                    }}
                  >
                    {memberList.map((member) => (
                      <Option
                        disabled={member.role === ECompanyRole.SUPER_ADMIN}
                        key={member.uid}
                        value={member.uid}
                        label={`${member.username}${
                          member.realname ? `(${member.realname})` : ''
                        }`}
                      >
                        <div className={styles.member_option}>
                          <div className={styles.member_name}>
                            <span
                              className={styles.qName}
                              style={{ marginRight: 16 }}
                            >
                              {highlightText(member.username)}
                            </span>
                            <span className={styles.qValue}>
                              uid：{highlightText(String(member.uid))}
                            </span>
                          </div>
                          <div className={styles.member_detail}>
                            <div style={{ marginRight: 20 }}>
                              <span className={styles.qName}>手机号：</span>
                              <span className={styles.qValue}>
                                {highlightText(member.mobile)}
                              </span>
                            </div>
                            <div style={{ marginRight: 20 }}>
                              <span className={styles.qName}>角色：</span>
                              <span className={styles.qValue}>
                                {companyRoleMap[member.role]}
                              </span>
                            </div>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <div className={styles.qName}>姓名：</div>
                              <div className={styles.qValue_ellipsis}>
                                {highlightText(member.realname || '--')}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Option>
                    ))}
                  </Select>

                  {selectedMembers.length > 0 && (
                    <div className={styles.selectedMembers}>
                      {selectedMembers.map((member, index) => (
                        <MemberCard
                          key={index}
                          index={index + 1}
                          member={member}
                          handleRemoveMember={handleRemoveMember}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </Form.Item>

              <Form.Item
                label={'与客户沟通记录'}
                name={'communication'}
                rules={[
                  {
                    required: true,
                    message: '请上传与客户沟通的截图',
                    validator: (_, value) => {
                      if (!value || value.length === 0) {
                        return Promise.reject(
                          new Error('请上传与客户沟通的截图'),
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <div className={styles.uploadWrapper}>
                  <Dragger
                    {...uploadProps}
                    className={styles.upload}
                    disabled={fileList.length >= 10}
                    customRequest={(info) => {
                      console.log('info :', info);
                      const file = info.file as RcFile;
                      const isJpgOrPng =
                        file.type === 'image/png' ||
                        file.type === 'image/jpeg' ||
                        file.type === 'image/jpg';
                      if (!isJpgOrPng) {
                        message.error('只支持.png/.jpeg格式!');
                      }
                      const isLt2M = file.size <= 10 * 1024 * 1024;
                      if (!isLt2M) {
                        message.error('大小不超过10MB!');
                      }
                      if (!isJpgOrPng || !isLt2M) {
                        return Promise.reject();
                      }
                      // 1. 返回 Promise 以追踪上传状态
                      const fileInfo = getFileNameAndType(file.name);
                      const uploadParams = calFileUploadParams(
                        fileInfo.type,
                        'myfr',
                      );

                      // 2. 提交文件上传参数
                      submitFile({
                        path: 'myfr',
                        fileName: uploadParams.fileName,
                        contentType: file.type,
                      })
                        .then((res) => {
                          const resData = res.data;
                          axios.request({
                            method: 'PUT',
                            url: resData.signedUrl,
                            withCredentials: false,
                            headers:
                              Object.assign(
                                resData.actualSignedRequestHeaders,
                                {
                                  'Content-Disposition': `attachment; filename=${encodeURIComponent(
                                    file?.name,
                                  )}`,
                                },
                              ) || {},
                            data: file,
                          });
                        })
                        .then(() => {
                          getCoverBase64(info.file, (imageUrl: any) => {
                            setFileList((prevState) => [
                              ...prevState,
                              {
                                uid: randomStr(7),
                                name: file?.name,
                                data: imageUrl,
                                url: `https://shequ-oss.obs.cn-east-2.myhuaweicloud.com:443/myfr/${uploadParams.fileName}`,
                                status: 'done',
                              },
                            ]);
                          });
                        });
                    }}
                  >
                    <div className={styles.uploadContent}>
                      <div className={styles.uploadText}>
                        点击或拖拽文件到此处上传
                      </div>
                      <div className={styles.uploadTip}>
                        支持上传文件大小不超过 10 MB 的 .png\.jpg 格式图片
                      </div>
                    </div>
                  </Dragger>
                  {fileList.length > 0 && (
                    <div className={styles.fileList}>
                      {fileList.map((file) => (
                        <div key={file.uid} className={styles.uploadItem}>
                          <div className={styles.fileInfo}>
                            <img
                              src={file.data}
                              className={styles.thumbnail}
                              // alt="thumbnail"
                            />
                            <div className={styles.fileName}>{file.name}</div>
                          </div>
                          <CloseOutlined
                            className={styles.deleteIcon}
                            onClick={() => {
                              const newFileList = fileList.filter(
                                (item) => item.uid !== file.uid,
                              );
                              setFileList(newFileList);
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Form.Item>

              <Form.Item
                label={'操作备注'}
                name={'remarks'}
                rules={[
                  {
                    required: false,
                    max: 500,
                    message: '备注不能超过500字',
                  },
                ]}
              >
                <TextArea
                  placeholder={'记录操作原因等信息'}
                  rows={4}
                  maxLength={500}
                  showCount={{
                    formatter: ({ count, maxLength }) =>
                      `${count}/${maxLength}`,
                  }}
                />
              </Form.Item>
            </Form>
          </React.Fragment>
        )}
      </div>
      <div className={styles.bottom_buttons_wrapper}>
        <div className={styles.bottom_button}>
          {submitFinish ? (
            <Button
              onClick={() => {
                form.resetFields();
                setRemoveOriginCompany(undefined);
                setSelectedMembers([]);
                setFileList([]);
                setSubmitFinish(false);
                setMemberSearchValue(undefined);
              }}
            >
              继续为其他客户操作
            </Button>
          ) : (
            <Button
              type={'primary'}
              onClick={handleRemoveConfirm}
              disabled={
                removeOriginCompany === undefined ||
                selectedMembers.length === 0 ||
                fileList.length === 0
              }
            >
              移除成员
            </Button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DeleteMembersForm;
