import React from 'react';

import PermissionCard from './PermissionCard';
import styles from './index.less';
import { EPermissionGroup } from './type';
import { useModel } from '@@/plugin-model/useModel';
import useGetUserInfo from '@/hooks/useGetUserInfo';
import { NoAccessPage } from '@digit-fe/digit-component';

const PermissionManagement: React.FC = () => {
  const { initialState } = useModel('@@initialState');
  const userInfo = useGetUserInfo();
  const { isFrAccount } = initialState;
  return (
    <React.Fragment>
      {userInfo.permission.admin ? (
        <div className={styles.permission_container}>
          <div className={styles.permission_management}>
            <PermissionCard title={'超级管理员'} authority={false} />
            <PermissionCard
              type={EPermissionGroup.ADD_MEMBER}
              title={'谁可以使用“向企业添加成员”'}
              subTitle={'点击下方区域修改权限组人员。'}
            />
            <PermissionCard
              type={EPermissionGroup.REMOVE_MEMBER}
              title={'谁可以使用“移除企业成员”功能'}
              subTitle={'点击下方区域修改权限组人员。'}
            />
            <PermissionCard
              type={EPermissionGroup.MERGE_COMPANY}
              title={'谁可以使用“企业合并”功能'}
              subTitle={'点击下方区域修改权限组人员。'}
            />
          </div>
        </div>
      ) : (
        <NoAccessPage
          switchAccountHref={`${process.env.ID_URL}/login/token/logout?referrer=${window.location.href}`}
          isFrAccount={isFrAccount}
        />
      )}
    </React.Fragment>
  );
};

export default PermissionManagement;
