import React, { useState } from 'react';
import styles from './index.less';
import { Form, Input, message, Select, Space } from 'antd';
import { phoneCode } from '@/utils/phoneCode';
import { validateMobile } from '@/services/enterpriseServiceBackend';
import { isUndefined } from 'lodash';
import { useModel } from '@@/plugin-model/useModel';
import {
  companyCooperateTypeMap,
  companyRoleMap,
  ECompanyReviewState,
} from '@/types';
import cx from 'classnames';
import EnterpriseTag from '@/components/enterpriseTag';
import { ETagColor } from '@/components/enterpriseTag/color';
import dayjs from 'dayjs';

const FindAccount: React.FC<{}> = () => {
  // 校验 loading 状态
  const [loading, setLoading] = useState<boolean>(false);
  const {
    customerAccountInfo,
    setCustomerAccountInfo,
    customerCompanyInfo,
    setCustomerCompanyInfo,
    setMobileValid,
    setMobile,
  } = useModel('useAddMembersModel', (model) => ({
    customerAccountInfo: model.customerAccountInfo,
    setCustomerAccountInfo: model.setCustomerAccountInfo,
    customerCompanyInfo: model.customerCompanyInfo,
    setCustomerCompanyInfo: model.setCustomerCompanyInfo,
    setMobileValid: model.setMobileValid,
    setMobile: model.setMobile,
  }));
  const validateMobileRun = async (rule: any, value: string) => {
    if (!value) {
      return Promise.reject('');
    }
    setMobile(value);
    setLoading(true);
    try {
      const response = await validateMobile(value);
      setLoading(false);
      if (isUndefined(response.data.uid)) {
        setCustomerAccountInfo(undefined);
        setCustomerCompanyInfo(undefined);
        if (!response.data.valid) {
          setMobileValid(false);
          return Promise.reject('请正确填写客户手机号');
        } else {
          setMobileValid(true);
          return Promise.reject(
            '未匹配到已有账号，操作完成后将自动为客户创建帆软账号，客户可用手机号+验证码登录',
          );
        }
      }
      setMobileValid(true);
      setCustomerAccountInfo(response.data);
      if (!isUndefined(response.data.comInfo)) {
        setCustomerCompanyInfo(response.data.comInfo);
      } else {
        setCustomerCompanyInfo(undefined);
      }
    } catch (error) {
      setMobileValid(false);
      setLoading(false);
      message.error('校验失败，请稍后再试');
      return Promise.reject('校验失败');
    }
  };
  // 企业合作认证审核状态
  const companyReviewStateMap: {
    [key in ECompanyReviewState]: () => void;
  } = {
    [ECompanyReviewState.FAILED]: () => null,
    [ECompanyReviewState.WAITING]: () => (
      <EnterpriseTag color={ETagColor.ORANGE} text={'待审核'} />
    ),
    [ECompanyReviewState.PASSED]: () => (
      <EnterpriseTag color={ETagColor.GREEN} text={'通过'} />
    ),
  };
  const handleTime = (time: string | undefined) => {
    if (time) {
      return dayjs(time).format('YYYY-MM-DD');
    }
    return '未知时间';
  };
  // 企业合作认证关键时间点
  const companyReviewTimeMap: {
    [key in ECompanyReviewState]: (
      entryTime: string,
      reviewTime: string,
    ) => void;
  } = {
    [ECompanyReviewState.FAILED]: (entryTime, reviewTime) => null,
    [ECompanyReviewState.WAITING]: (entryTime, reviewTime) => (
      <EnterpriseTag text={`${handleTime(entryTime)}申请`} />
    ),
    [ECompanyReviewState.PASSED]: (entryTime, reviewTime) => (
      <EnterpriseTag text={`${handleTime(reviewTime)}通过`} />
    ),
  };

  return (
    <React.Fragment>
      <Form.Item
        name={'mobile'}
        label={'客户手机号'}
        required={true}
        rules={[
          { required: true, message: '请输入客户手机号' },
          { validator: validateMobileRun },
        ]}
        validateTrigger={['onBlur', 'onPressEnter']}
        hasFeedback={true}
        validateStatus={loading ? 'validating' : ''}
      >
        <Input
          placeholder={'请输入客户手机号'}
          addonBefore={
            <Form.Item name={'location'}>
              <Select style={{ width: 80 }}>
                {phoneCode.map((item) => (
                  <Select.Option value={item.value}>{item.value}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          }
        />
      </Form.Item>
      {customerAccountInfo && (
        <div className={styles.customer_info_wrapper}>
          <Space direction={'vertical'} size={8}>
            <div>
              <span className={styles.name}>
                {customerAccountInfo.username}
              </span>
              <span>uid：{customerAccountInfo.uid}</span>
            </div>
            <div>
              <span className={styles.mgr14}>手机号：</span>
              <span className={cx(styles.mgr29, styles.value)}>
                {customerAccountInfo.mobile}
              </span>
              <span>真实姓名：</span>
              <span className={styles.value}>
                {customerAccountInfo.realName}
              </span>
            </div>
            <div>
              名片关联CRM公司：
              {isUndefined(customerAccountInfo.crmCardList) ||
              customerAccountInfo.crmCardList?.length == 0 ? (
                <span className={styles.value}>--</span>
              ) : (
                customerAccountInfo.crmCardList.map((item, index) => (
                  <React.Fragment key={item.comId}>
                    {index > 0 && '、'}
                    <a
                      target={'_blank'}
                      href={`https://crm.finereporthelp.com/WebReport/decision/view/report?viewlet=customer/company_view.cpt&op=view&comid=${item.comId}`}
                    >
                      {item.crmComName}
                    </a>
                  </React.Fragment>
                ))
              )}
            </div>
          </Space>
        </div>
      )}
      {customerCompanyInfo && (
        <React.Fragment>
          <div className={styles.enterprise_title}>当前所在企业：</div>
          <div className={styles.enterprise_info_wrapper}>
            <Space direction={'vertical'} size={8}>
              <div className={styles.name}>{customerCompanyInfo.comname}</div>
              <div>
                <span>mvid：</span>
                <span className={cx(styles.value, styles.mgr40)}>
                  {customerCompanyInfo.mvId}
                </span>
                <span>角色：</span>
                <span className={styles.value}>
                  {customerCompanyInfo.role == undefined
                    ? '获取失败'
                    : companyRoleMap[customerCompanyInfo.role]}
                </span>
              </div>
              <div>
                <Space size={4} wrap={true}>
                  {(!isUndefined(customerCompanyInfo.state) &&
                    companyReviewStateMap[customerCompanyInfo.state]()) ||
                    null}
                  {(!isUndefined(customerCompanyInfo.type) && (
                    <EnterpriseTag
                      text={`${
                        companyCooperateTypeMap[customerCompanyInfo.type]
                      }`}
                    />
                  )) ||
                    null}
                  <EnterpriseTag
                    text={`经办人：${
                      customerCompanyInfo.assignReviewerName || '--'
                    }`}
                  />
                  {(!isUndefined(customerCompanyInfo.state) &&
                    companyReviewTimeMap[customerCompanyInfo.state](
                      customerCompanyInfo.entryTime,
                      customerCompanyInfo.reviewTime,
                    )) ||
                    null}
                </Space>
              </div>
            </Space>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default FindAccount;
